import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Link from "@mui/material/Link";
import PageBanner from "components/common/PageBanner";
import ReactMarkdown from "react-markdown";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import posts from "components/pages/Blog/posts";

const slugToTitle = Object.fromEntries(
  posts.map(({ slug, title }) => [slug, title])
);

const toLocalDateString = (dateString) =>
  new Date(dateString).toLocaleDateString("vi-VN", { dateStyle: "short" });

const compareDates = ({ date: date1 }, { date: date2 }) => {
  if (date1 < date2) {
    return -1;
  }
  if (date1 > date2) {
    return 1;
  }
  return 0;
};

function PostTile({ slug, image, date, title }) {
  const navigate = useNavigate();

  const goToPost = () => {
    navigate(`/blog/${slug}`);
  };

  return (
    <Stack spacing={2} width={300}>
      <img
        src={image}
        alt={title}
        width={300}
        style={{ borderRadius: 16, cursor: "pointer" }}
        onClick={goToPost}
      />
      <Typography variant="body2" color="info.main">
        {date}
      </Typography>
      <Typography
        variant="h6"
        color="info.main"
        sx={{ fontWeight: "bold", cursor: "pointer" }}
        onClick={goToPost}
      >
        {title}
      </Typography>
    </Stack>
  );
}

function PostContent({ image, date, title, markdown }) {
  return (
    <Stack alignItems="center" spacing={4} pl={4} pr={4}>
      <Stack spacing={1}>
        <div style={{ maxWidth: 600 }}>
          <img
            src={image}
            alt={title}
            loading="lazy"
            width="100%"
            style={{ borderRadius: 16 }}
          />
        </div>
        <Typography variant="body2" color="info.main">
          {date}
        </Typography>
      </Stack>

      <Typography variant="h4" color="info.main">
        {title}
      </Typography>

      <Stack width="100%" maxWidth={750} spacing={2}>
        <ReactMarkdown
          components={{
            h1: ({ children }) => (
              <Typography
                variant="h4"
                color="info.main"
                textAlign="center"
                gutterBottom
              >
                {children}
              </Typography>
            ),
            h2: ({ children }) => (
              <Typography variant="h5" color="info.main">
                {children}
              </Typography>
            ),
            h3: ({ children }) => (
              <Typography variant="h6" color="info.main">
                {children}
              </Typography>
            ),
            p: ({ children }) => (
              <Typography variant="body1" color="info.main" gutterBottom>
                {children}
              </Typography>
            ),
            li: ({ children }) => (
              <Typography variant="body1" color="info.main" gutterBottom>
                {children}
              </Typography>
            ),
          }}
        >
          {markdown}
        </ReactMarkdown>
      </Stack>
    </Stack>
  );
}

function Blog() {
  const navigate = useNavigate();
  const { slug } = useParams();
  const upSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const truncate = (title) => (upSm ? title : `${title.slice(0, 20)}...`);

  const post = posts.find((post) => post.slug === slug);

  return (
    <Stack spacing={10}>
      <Helmet>
        <title>Blog</title>
        <meta
          name="description"
          content="Cập nhật những xu hướng phát triển giáo dục tiên tiến nhất trên thế giới để giúp con vươn xa hội nhập."
        />
        <link rel="canonical" href="https://englishtree.edu.vn/blog" />
      </Helmet>

      <PageBanner
        backgroundImage="/images/blue-banner.png"
        heading="Blog"
        middleLinks={
          slug && (
            <Link
              underline="hover"
              color="info.main"
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/blog")}
            >
              Blog
            </Link>
          )
        }
        pageName={slug ? truncate(slugToTitle[slug]) : "Blog"}
      />

      {!slug && (
        <Stack
          spacing={4}
          pl={4}
          pr={4}
          direction="row"
          justifyContent="center"
          useFlexGap
          flexWrap="wrap"
        >
          {[...posts]
            .sort(compareDates)
            .reverse()
            .map(({ slug, image, date, title }) => (
              <PostTile
                key={slug}
                slug={slug}
                image={image}
                date={toLocalDateString(date)}
                title={title}
              />
            ))}
        </Stack>
      )}

      {slug && (
        <PostContent
          image={post.image}
          date={toLocalDateString(post.date)}
          title={post.title}
          markdown={post.markdown}
        />
      )}
    </Stack>
  );
}

export default Blog;
