import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import Drawer from "@mui/material/Drawer";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Stack from "@mui/material/Stack";
import { navMenuItem } from "types";

const collapsedNavHeight = 60;

function CustomListItemText(props) {
  return (
    <ListItemText
      {...props}
      sx={{
        "& .MuiListItemText-primary": {
          fontFamily: "Linotte",
        },
      }}
    />
  );
}

function CollapsedNavBar({ courses, whyUs, company, news, library }) {
  const [shouldOpenNavBarDrawer, setShouldOpenNavBarDrawer] = useState(false);

  const [shouldExpandCoursesList, setShouldExpandCoursesList] = useState(false);
  const [shouldExpandWhyUsList, setShouldExpandWhyUsList] = useState(false);
  const [shouldExpandCompanyList, setShouldExpandCompanyList] = useState(false);
  const [shouldExpandNewsList, setShouldExpandNewsList] = useState(false);
  const [shouldExpandLibraryList, setShouldExpandLibraryList] = useState(false);

  const navigate = useNavigate();

  const collapseAllLists = () => {
    setShouldExpandCoursesList(false);
    setShouldExpandWhyUsList(false);
    setShouldExpandCompanyList(false);
    setShouldExpandLibraryList(false);
    setShouldExpandNewsList(false);
  };

  const makeHandleNav = (pathname) => () => {
    navigate(pathname);
    collapseAllLists();
    setShouldOpenNavBarDrawer(false);
  };

  const openNavBarDrawer = () => setShouldOpenNavBarDrawer(true);
  const closeNavBarDrawer = () => {
    collapseAllLists();
    setShouldOpenNavBarDrawer(false);
  };

  const toggleExpandCoursesList = () =>
    setShouldExpandCoursesList(!shouldExpandCoursesList);
  const toggleExpandWhyUsList = () =>
    setShouldExpandWhyUsList(!shouldExpandWhyUsList);
  const toggleExpandCompanyList = () =>
    setShouldExpandCompanyList(!shouldExpandCompanyList);
  const toggleExpandNewsList = () =>
    setShouldExpandNewsList(!shouldExpandNewsList);
  const toggleExpandLibraryList = () =>
    setShouldExpandLibraryList(!shouldExpandLibraryList);

  return (
    <>
      <AppBar position="fixed" elevation={1}>
        <Stack direction="row" alignItems="center">
          <IconButton size="large" color="secondary" onClick={openNavBarDrawer}>
            <MenuIcon />
          </IconButton>
          <img
            src="/images/logo.png"
            className="logo"
            alt="logo"
            height={collapsedNavHeight}
            onClick={() => navigate("/")}
          />
        </Stack>
      </AppBar>

      <Box sx={{ height: collapsedNavHeight }} />

      <Drawer
        anchor="left"
        open={shouldOpenNavBarDrawer}
        onClose={closeNavBarDrawer}
      >
        <Stack spacing={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ margin: 1 }}
          >
            <img
              src="/images/logo.png"
              className="logo"
              alt="logo"
              width="120px"
              onClick={makeHandleNav("/")}
            />
            <IconButton
              size="large"
              color="secondary"
              onClick={closeNavBarDrawer}
            >
              <CloseIcon />
            </IconButton>
          </Stack>

          <List disablePadding>
            <ListItemButton onClick={toggleExpandCoursesList}>
              <CustomListItemText primary="KHOÁ HỌC" />
              {shouldExpandCoursesList ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={shouldExpandCoursesList}>
              <List disablePadding>
                {courses.map(({ title, path }) => (
                  <ListItemButton
                    key={title}
                    sx={{ pl: 4 }}
                    onClick={makeHandleNav(path)}
                  >
                    <CustomListItemText primary={title} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>

            <ListItemButton onClick={toggleExpandWhyUsList}>
              <CustomListItemText primary="ĐIỂM ĐẶC BIỆT" />
              {shouldExpandWhyUsList ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={shouldExpandWhyUsList}>
              <List disablePadding>
                {whyUs.map(({ title, path }) => (
                  <ListItemButton
                    key={title}
                    sx={{ pl: 4 }}
                    onClick={makeHandleNav(path)}
                  >
                    <CustomListItemText primary={title} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>

            <ListItemButton onClick={toggleExpandCompanyList}>
              <CustomListItemText primary="GIỚI THIỆU" />
              {shouldExpandCompanyList ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={shouldExpandCompanyList}>
              <List disablePadding>
                {company.map(({ title, path }) => (
                  <ListItemButton
                    key={title}
                    sx={{ pl: 4 }}
                    onClick={makeHandleNav(path)}
                  >
                    <CustomListItemText primary={title} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>

            <ListItemButton onClick={toggleExpandNewsList}>
              <CustomListItemText primary="TIN TỨC" />
              {shouldExpandNewsList ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={shouldExpandNewsList}>
              <List disablePadding>
                {news.map(({ title, path }) => (
                  <ListItemButton
                    key={title}
                    sx={{ pl: 4 }}
                    onClick={makeHandleNav(path)}
                  >
                    <CustomListItemText primary={title} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>

            <ListItemButton onClick={toggleExpandLibraryList}>
              <CustomListItemText primary="THƯ VIỆN" />
              {shouldExpandLibraryList ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={shouldExpandLibraryList}>
              <List disablePadding>
                {library.map(({ title, path }) => (
                  <ListItemButton
                    key={title}
                    sx={{ pl: 4 }}
                    onClick={makeHandleNav(path)}
                  >
                    <CustomListItemText primary={title} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>

            <ListItemButton onClick={makeHandleNav("/lien-he")}>
              <CustomListItemText primary="LIÊN HỆ" />
            </ListItemButton>
          </List>
        </Stack>
      </Drawer>
    </>
  );
}

CollapsedNavBar.propTypes = {
  courses: PropTypes.arrayOf(navMenuItem).isRequired,
  whyUs: PropTypes.arrayOf(navMenuItem).isRequired,
  company: PropTypes.arrayOf(navMenuItem).isRequired,
  news: PropTypes.arrayOf(navMenuItem).isRequired,
  library: PropTypes.arrayOf(navMenuItem).isRequired,
};

export default CollapsedNavBar;
