import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router";

import AboutUs from "components/pages/AboutUs";
import Blog from "components/pages/Blog";
import ContactUs from "components/pages/ContactUs";
import CoursePage from "components/pages/CoursePage";
import Footer from "components/Footer";
import HomePage from "components/pages/HomePage";
import NavBar from "components/NavBar";
import Promotion from "components/pages/Promotion";
import QuickContact from "components/QuickContact";
import Stack from "@mui/material/Stack";
import UnderConstruction from "components/pages/UnderConstruction";
import WhyUs from "components/pages/WhyUs";

import { getEndpoint } from "helpers";
import "animate.css";
import "styles/App.css";

function App() {
  const [news, setNews] = useState([]);
  const [schedule, setSchedule] = useState([]);

  const location = useLocation();

  useEffect(() => {
    fetch(`${getEndpoint()}/.netlify/functions/get-data`)
      .then((response) => response.json())
      .then(({ news, schedule }) => {
        setNews(news);
        setSchedule(schedule);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (location.pathname !== "/diem-dac-biet") {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <div className="app">
      <NavBar />
      <Stack spacing={10}>
        <Routes>
          <Route
            path="/"
            element={<HomePage news={news} schedule={schedule} />}
          ></Route>
          <Route path="/khoa-hoc/:courseId" element={<CoursePage />}></Route>
          <Route path="/diem-dac-biet" element={<WhyUs />}></Route>
          <Route path="/ve-chung-toi" element={<AboutUs />}></Route>
          <Route path="/tuyen-dung" element={<UnderConstruction />}></Route>
          <Route path="/blog" element={<Blog />}></Route>
          <Route path="/blog/:slug" element={<Blog />}></Route>
          <Route path="/tin-tuc/uu-dai-vang" element={<Promotion />}></Route>
          <Route path="/tai-lieu" element={<UnderConstruction />}></Route>
          <Route path="/lien-he" element={<ContactUs />}></Route>
          <Route
            path="/*"
            element={<HomePage news={news} schedule={schedule} />}
          ></Route>
        </Routes>
        <Footer />
      </Stack>
      <QuickContact />
    </div>
  );
}

export default App;
