import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";

import App from "App";
import "styles/index.css";

let theme = createTheme({
  palette: {
    primary: {
      main: "#37b995",
      opaque: "#ebf8f4",
    },
    secondary: {
      main: "#f37820",
      opaque: "#fef2e9",
    },
    info: {
      main: "#15137c",
    },
    tertiary: {
      blue: {
        main: "#00a3c9",
        light: "#33b5d3",
        dark: "#00728c",
        opaque: "#e5f6f9",
      },
      yellow: {
        main: "#eab622",
        light: "#eec44e",
        dark: "#a37f17",
        opaque: "#fdf8e9",
      },
      mold: {
        main: "#a5c6a9",
        light: "#b7d1ba",
        dark: "#738a76",
      },
      pink: {
        main: "#fd8087",
        light: "#fd999f",
        dark: "#b1595e",
        opaque: "#fff3f3",
      },
    },
  },
  typography: {
    fontFamily: "Montserrat",
    h1: {
      fontFamily: "Linotte",
    },
    h2: {
      fontFamily: "Linotte",
    },
    h3: {
      fontFamily: "Linotte",
    },
    h4: {
      fontFamily: "Linotte",
    },
    h5: {
      fontFamily: "Linotte",
    },
    h6: {
      fontFamily: "Linotte",
    },
    button: {
      fontFamily: "Linotte",
    },
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        color: "inherit",
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === "text" && {
            color: theme.palette.info.main,
          }),
          ...(ownerState.variant === "contained" && {
            color: theme.palette.common.white,
          }),
        }),
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.info.main,
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.info.main,
        }),
      },
    },
  },
});

theme = responsiveFontSizes(theme);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);
