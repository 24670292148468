import React, { useState } from "react";
import PropTypes from "prop-types";

import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import Column from "components/core/Column";
import IconButton from "@mui/material/IconButton";
import Row from "components/core/Row";

import "./Carousel.css";

function Carousel({ className, spacing, startIndex, numberToShow, children }) {
  const [firstItemIndex, setFirstItemIndex] = useState(startIndex);

  const handleClickLeftArrow = () => {
    setFirstItemIndex((firstItemIndex - 1 + children.length) % children.length);
  };

  const handleClickRightArrow = () => {
    setFirstItemIndex((firstItemIndex + 1) % children.length);
  };

  return (
    <Column className={className} width="100%" alignmentHorizontal="center">
      <Row
        width="100%"
        alignmentVertical="center"
        style={{ justifyContent: "space-evenly" }}
      >
        <IconButton color="secondary" onClick={handleClickLeftArrow}>
          <ArrowBackIosRoundedIcon />
        </IconButton>

        <Row spacing={spacing}>
          {children.map((child, index) => {
            if (
              index < firstItemIndex ||
              index >= firstItemIndex + numberToShow
            ) {
              return null;
            }
            return (
              <div key={index} className="fade">
                {child}
              </div>
            );
          })}
        </Row>

        <IconButton color="secondary" onClick={handleClickRightArrow}>
          <ArrowForwardIosRoundedIcon />
        </IconButton>
      </Row>
    </Column>
  );
}

Carousel.propTypes = {
  className: PropTypes.string,
  spacing: PropTypes.oneOf(["small", "medium", "large"]),
  startIndex: PropTypes.number.isRequired,
  numberToShow: PropTypes.number.isRequired,
  children: PropTypes.array.isRequired,
};

export default Carousel;
