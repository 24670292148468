import { useEffect, useState } from "react";

const initialRatio = window.devicePixelRatio;

export function useDevice() {
  const getDevice = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth <= 480) {
      return {
        size: 1,
        name: "mobile",
      };
    }
    if (windowWidth <= 800) {
      return {
        size: 2,
        name: "tablet",
      };
    }
    if (windowWidth <= 1200) {
      return {
        size: 3,
        name: "laptop",
      };
    }
    return {
      size: 4,
      name: "wide-screen",
    };
  };

  const [device, setDevice] = useState(getDevice());

  useEffect(() => {
    const handleWindowResize = () => {
      if (initialRatio === window.devicePixelRatio) {
        setDevice(getDevice());
      }
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return device;
}
