import React from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function Paragraph({ heading, body, list }) {
  return (
    <Stack>
      {heading && (
        <Typography
          variant="body1"
          color="info.main"
          sx={{ fontWeight: "bold", mb: body ? 2 : 0 }}
        >
          {heading}
        </Typography>
      )}

      {body && (
        <Stack spacing={2} sx={{ mb: list ? 0 : 2 }}>
          {typeof body === "string" ? (
            <Typography variant="body1" color="info.main">
              {body}
            </Typography>
          ) : (
            body.map((item) => (
              <Typography key={item} variant="body1" color="info.main">
                {item}
              </Typography>
            ))
          )}
        </Stack>
      )}

      {list && (
        <List>
          {list.map((item) => (
            <ListItem key={item} sx={{ pt: 0, pb: 0, pl: 5 }}>
              <ListItemText
                primary={item}
                primaryTypographyProps={{ color: "info.main" }}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Stack>
  );
}

export default Paragraph;
