import React from "react";
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function Secret({ icon, borderColor, secret, searchParam }) {
  const navigate = useNavigate();

  return (
    <Card
      className="shake-head"
      elevation={0}
      sx={{
        borderRadius: 4,
        "&:hover": { cursor: "pointer" },
      }}
      onClick={() => navigate(`/diem-dac-biet?ddb=${searchParam}`)}
    >
      <CardContent
        sx={{ borderColor, borderStyle: "dashed", borderRadius: 3, margin: 2 }}
      >
        <Stack alignItems="center" width={250} spacing={2}>
          <img src={icon} alt={secret} width={100} />
          <Typography variant="h6" color="info.main" align="center">
            {secret}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

function WhyUs() {
  return (
    <Stack
      alignItems="center"
      spacing={4}
      pt={8}
      pb={8}
      sx={{ backgroundColor: "primary.light" }}
    >
      <Typography variant="h2" color="common.white" align="center">
        Điểm đặc biệt
      </Typography>

      <Stack
        direction="row"
        justifyContent="center"
        useFlexGap
        flexWrap="wrap"
        spacing={4}
        maxWidth={1200}
      >
        <Secret
          icon="/images/recipe-icon.png"
          borderColor="primary.main"
          secret="Công thức bí mật"
          searchParam="cong-thuc-bi-mat"
        />
        <Secret
          icon="/images/ingredients-icon.png"
          borderColor="secondary.main"
          secret="Nguyên liệu chất lượng"
          searchParam="nguyen-lieu-chat-luong"
        />
        <Secret
          icon="/images/expertise-icon.png"
          borderColor="tertiary.blue.main"
          secret="Chuyên gia tay nghề cao"
          searchParam="chuyen-gia-tay-nghe-cao"
        />
        <Secret
          icon="/images/presentation-icon.png"
          borderColor="tertiary.yellow.main"
          secret="Trang trí hài hoà "
          searchParam="trang-tri-hai-hoa"
        />
        <Secret
          icon="/images/service-icon.png"
          borderColor="tertiary.pink.main"
          secret="Phục vụ chu đáo"
          searchParam="phuc-vu-chu-dao"
        />
      </Stack>
    </Stack>
  );
}

export default WhyUs;
