import React from "react";
import PropTypes from "prop-types";

import NewsFlash from "components/pages/HomePage/HeroBanner/NewsFlash";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

function HeroBanner({ news }) {
  const upSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const upMd = useMediaQuery((theme) => theme.breakpoints.up("md"));

  let padding = 4;
  if (upSm) {
    padding = 6;
  }
  if (upMd) {
    padding = 10;
  }

  return (
    <Stack direction="row" sx={{ backgroundColor: "secondary.opaque" }}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        useFlexGap
        flexWrap="wrap"
        width="100%"
        pt={padding}
        pb={padding}
        pl={4}
        pr={4}
        spacing={8}
      >
        <Stack width={upMd ? "50%" : "90%"} maxWidth={800}>
          <img
            src="https://i.postimg.cc/zDSKdB3F/hero-banner-image.png"
            alt="kids"
            width="100%"
          />
        </Stack>

        <Stack alignItems="center" spacing={4}>
          <Typography
            variant="h1"
            color="info.main"
            align="center"
            sx={{ userSelect: "none" }}
          >
            Anh ngữ toàn diện cho trẻ em
          </Typography>

          <NewsFlash news={news} />
        </Stack>
      </Stack>
    </Stack>
  );
}

HeroBanner.propTypes = {
  news: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default HeroBanner;
