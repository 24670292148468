import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function NewsFlashItem({ heading, body }) {
  const navigate = useNavigate();

  return (
    <Stack
      maxWidth={520}
      spacing={2}
      style={{ cursor: "pointer" }}
      onClick={() => navigate("/lien-he")}
    >
      <Typography variant="h5" color="common.white">
        {heading}
      </Typography>
      <Typography variant="body1" color="common.white">
        {body}
      </Typography>
    </Stack>
  );
}

function NewsFlash({ news }) {
  const [newsFlashItems, setNewsFlashItems] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const intervalRef = useRef(null);

  useEffect(() => {
    setNewsFlashItems(
      news.map(({ heading, body }) => (
        <NewsFlashItem heading={heading} body={body} />
      )),
    );
  }, [news]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setActiveIndex(
        (prevActiveIndex) => (prevActiveIndex + 1) % newsFlashItems.length,
      );
    }, 4000);

    return () => clearInterval(intervalRef.current);
  }, [newsFlashItems]);

  const restartInterval = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setActiveIndex(
        (prevActiveIndex) => (prevActiveIndex + 1) % newsFlashItems.length,
      );
    }, 4000);
  };

  if (!newsFlashItems.length) {
    return null;
  }

  return (
    <Card
      sx={{
        backgroundColor: "primary.main",
        borderRadius: 4,
        padding: 2,
        pb: 1,
      }}
    >
      <CardContent>
        <Stack spacing={4}>
          {newsFlashItems[activeIndex]}

          <Stack direction="row" justifyContent="center" spacing={2}>
            {newsFlashItems.map((_, index) => (
              <div
                key={index}
                style={{
                  width: 10,
                  height: 10,
                  borderColor: "white",
                  borderStyle: "solid",
                  borderRadius: "50%",
                  borderWidth: "thin",
                  backgroundColor:
                    index === activeIndex ? "white" : "transparent",
                  cursor: "pointer",
                }}
                onClick={() => {
                  restartInterval();
                  setActiveIndex(index);
                }}
              />
            ))}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

NewsFlash.propTypes = {
  news: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default NewsFlash;
