import React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function ContactDetail() {
  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: "primary.light",
        borderRadius: 4,
        padding: 1,
      }}
    >
      <CardContent>
        <Stack alignItems="center" width="100%" spacing={1}>
          <Stack justifyContent="flex-end" height={110}>
            <img
              src="/images/location.png"
              className="pulse"
              alt="location"
              height={100}
            />
          </Stack>
          <Typography
            variant="h6"
            color="common.white"
            align="center"
            sx={{ fontWeight: "bold" }}
          >
            Địa chỉ
          </Typography>
          <Typography variant="body1" color="common.white" align="center">
            <div>
              <div>400 Lê Hồng Phong, Tân Đông Hiệp, Dĩ An, Bình Dương</div>
              <div>Số 29 đường số 5, KP Thống Nhất 1, Dĩ An, Bình Dương</div>
            </div>
          </Typography>

          <Stack justifyContent="flex-end" height={110}>
            <img
              src="/images/phone.png"
              className="pulse"
              alt="phone"
              height={100}
            />
          </Stack>
          <Typography
            variant="h6"
            color="common.white"
            align="center"
            sx={{ fontWeight: "bold" }}
          >
            Điện thoại
          </Typography>
          <Typography variant="body1" color="common.white" align="center">
            0966 766 839 - 0274 7306839
          </Typography>

          <Stack justifyContent="flex-end" height={110}>
            <img
              src="/images/email.png"
              className="pulse"
              alt="email"
              height={110}
            />
          </Stack>
          <Typography
            variant="h6"
            color="common.white"
            align="center"
            sx={{ fontWeight: "bold" }}
          >
            Email
          </Typography>
          <Typography variant="body1" color="common.white" align="center">
            theenglishtree.edu@gmail.com
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

function ContactDetails() {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      useFlexGap
      flexWrap="wrap"
      spacing={2}
    >
      <ContactDetail />
    </Stack>
  );
}

export default ContactDetails;
