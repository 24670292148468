import React from "react";

import CustomTable from "components/common/CustomTable";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paragraph from "components/common/Paragraph";

function CourseTable({ headerBackgroundColor, headers, rows, notes }) {
  return (
    <Stack spacing={4}>
      <Stack direction="row" justifyContent="center">
        <Typography
          className="highlight"
          variant="h2"
          color="info.main"
          align="center"
        >
          Lộ trình học
        </Typography>
      </Stack>

      <Stack direction="row" justifyContent="center">
        <Stack spacing={3} sx={{ overflowX: "hidden" }}>
          <CustomTable
            headerBackgroundColor={headerBackgroundColor}
            headers={headers}
            rows={rows}
          />
          {notes && <Paragraph heading="Ghi chú" list={notes} />}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default CourseTable;
