import React, { useState } from "react";

import Backdrop from "@mui/material/Backdrop";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import Carousel from "components/core/Carousel";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

const images = [
  {
    img: "https://i.postimg.cc/PJtpGWNz/image-1.png",
    alt: "image-1",
  },
  {
    img: "https://i.postimg.cc/NG426zPN/image-2.png",
    alt: "image-2",
  },
  {
    img: "https://i.postimg.cc/K8VMK3sx/image-3.png",
    alt: "image-3",
  },
  {
    img: "https://i.postimg.cc/0yrS20nV/image-4.png",
    alt: "image-4",
  },
  {
    img: "https://i.postimg.cc/Qx85v3TG/image-5.png",
    alt: "image-5",
  },
  {
    img: "https://i.postimg.cc/Hs65ghwJ/image-6.png",
    alt: "image-6",
  },
];

function Album() {
  const [shouldShowSlideShow, setShouldShowSlideShow] = useState(false);
  const [startImageIndex, setStartImageIndex] = useState(0);

  const upSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const upLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const showSlideShow = (index) => {
    setShouldShowSlideShow(true);
    setStartImageIndex(index);
  };
  const dismissSlideShow = () => setShouldShowSlideShow(false);

  let cols = 1;
  if (upSm) cols = 2;
  if (upLg) cols = 3;

  return (
    <div>
      <Stack alignItems="center">
        <Typography
          className="highlight"
          variant="h2"
          color="info.main"
          align="center"
          gutterBottom
        >
          Một số hình ảnh
        </Typography>

        <ImageList sx={{ width: "50%", maxWidth: 1200 }} cols={cols}>
          {images.map((image, index) => (
            <Card key={image.alt} elevation={0}>
              <CardActionArea onClick={() => showSlideShow(index)}>
                <ImageListItem>
                  <img src={image.img} alt={image.title} loading="lazy" />
                </ImageListItem>
              </CardActionArea>
            </Card>
          ))}
        </ImageList>
      </Stack>

      {shouldShowSlideShow && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={true}
          onClick={() => {}}
        >
          <Stack
            width="100%"
            height="100%"
            justifyContent="center"
            sx={{ position: "relative" }}
          >
            <IconButton
              onClick={dismissSlideShow}
              sx={{ position: "absolute", top: 0, right: 0, margin: "20px" }}
            >
              <CloseIcon color="secondary" />
            </IconButton>

            <Carousel
              spacing="large"
              startIndex={startImageIndex}
              numberToShow={1}
            >
              {images.map((image) => (
                <img
                  key={image.alt}
                  src={image.img}
                  alt={image.alt}
                  width="100%"
                  loading="lazy"
                />
              ))}
            </Carousel>
          </Stack>
        </Backdrop>
      )}
    </div>
  );
}

export default Album;
