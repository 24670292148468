import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import ExamTrainingTable from "components/pages/CoursePage/ExamTrainingTable";
import KindyTable from "components/pages/CoursePage/KindyTable";
import PageBanner from "components/common/PageBanner";
import PrimaryAdvancedTable from "components/pages/CoursePage/PrimaryAdvancedTable";
import PrimaryBasicTable from "components/pages/CoursePage/PrimaryBasicTable";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

const courses = {
  kindy: {
    bullet: "/images/kindy.png",
    icon: "/images/kindy-icon.png",
    image1: "https://i.postimg.cc/C5YDhyr8/kindy-image-1.jpg",
    image2: "https://i.postimg.cc/pdwzS5Dq/kindy-image-2.jpg",
    courseName: "Mầm non Kindy",
    courseDescription: [
      "Chương trình dành cho học sinh trong độ tuổi mầm non (3.5-6 tuổi) bắt đầu làm quen với tiếng Anh.",
      "Sĩ số lớp: 15 học viên",
      "Giáo viên nước ngoài 50%, giáo viên Việt Nam 50%",
    ],
    sellingPoints: [
      "Chương trình đi kèm phần mềm học tập tại nhà cho học sinh.",
      "Chương trình dạy phát âm đi kèm trong mỗi bài học.",
      "Chương trình phát triển kỹ năng ngôn ngữ hài hoà với các kỹ năng sống quan trọng khác của trẻ như khả năng nhận thức, khả năng vận động, khả năng sáng tạo và cảm xúc.",
      "Các hoạt động học tập kết hợp cùng bài hát, trò chơi, hoạt động thể chất, hoạt động thủ công, đọc truyện, diễn kịch … giúp học sinh luôn cảm thấy hào hứng khi đến trường.",
      "Học sinh bước đầu làm quen với các dự án thủ công, dự án khoa học, thí nghiệm và học cách giới thiệu về dự án hoặc trình bày kết quả thí nghiệm.",
      "Nội dung học đan xen giữa ngôn ngữ, làm toán và khoa học là bước đệm cho học sinh học lên những chương trình nâng cao sau này.",
      "Bài học về giá trị đạo đức và sự chia sẻ được lồng ghép giúp học sinh phát triển trí thông minh cảm xúc.",
    ],
    Table: KindyTable,
  },
  "pho-thong": {
    bullet: "/images/primary-basic.png",
    icon: "/images/primary-basic-icon.png",
    image1: "https://i.postimg.cc/vHbLMnCK/primary-basic-image-1.jpg",
    image2: "https://i.postimg.cc/ZKHPqRS5/primary-basic-image-2.jpg",
    courseName: "Tiếng Anh phổ thông",
    courseDescription: [
      "Chương trình dành cho học sinh trong độ tuổi tiểu học và trung học cơ sở (6-15 tuổi) có nguyện vọng phát triển toàn diện các kỹ năng Nghe–Nói–Đọc–Viết nhưng thiên về hướng vững ngữ pháp, hoàn thành tốt các bài thi quy định trong hệ thống bài thi tiếng Anh của Bộ Giáo dục đào tạo Việt Nam.",
      "Học viên tham gia đánh giá năng lực và được xếp lớp học có trình độ phù hợp.",
      "Sĩ số lớp: 15 học viên",
      "Giáo viên nước ngoài 30%, giáo viên Việt Nam 70%",
    ],
    sellingPoints: [
      "Chương trình bám sát chương trình đào tạo của Bộ Giáo dục và Đào tạo Việt Nam.",
      "Chương trình dạy phát âm đi kèm trong mỗi bài học.",
      "Các bài tập thực hành và bài tập ngữ pháp được giới thiệu xuyên suốt quá trình học giúp học viên nắm vững ngữ pháp tiếp Anh từ đó tạo tiền đề cho việc học các kỹ năng khác như Nghe-Nói-Đọc-Viết.",
      "Chương trình lồng ghép các nội dung học về văn học, toán, khoa học, kỹ năng sống giúp học viên phát triển kỹ năng ngôn ngữ song song với một số kỹ năng mềm cần thiết.",
      "Các trò chơi, bài hát, câu chuyện vui nhộn giúp tăng sự hứng thú học tập cho học sinh.",
      "Các hoạt động giao tiếp, làm việc nhóm và thuyết trình cũng được lồng ghép để học viên tăng sự tự tin khi sử dụng tiếng Anh.",
    ],
    Table: PrimaryBasicTable,
  },
  "quoc-te": {
    bullet: "/images/primary-advanced.png",
    icon: "/images/primary-advanced-icon.png",
    image1: "https://i.postimg.cc/RZbccDqr/primary-advanced-image-1.jpg",
    image2: "https://i.postimg.cc/PfmzByVZ/primary-advanced-image-2.jpg",
    courseName: "Tiếng Anh quốc tế",
    courseDescription: [
      "Chương trình dành cho học sinh trong độ tuổi tiểu học và trung học cơ sở (6-15 tuổi) có nguyện vọng phát triển toàn diện các kỹ năng Nghe–Nói–Đọc–Viết nhưng thiên về hướng giao tiếp và chú trọng vào sự tự tin khi sử dụng tiếng Anh.",
      "Học viên tham gia đánh giá năng lực và được xếp lớp học có trình độ phù hợp.",
      "Sĩ số lớp: 15 học viên",
      "Giáo viên nước ngoài 50%, giáo viên Việt Nam 50%",
    ],
    sellingPoints: [
      "Chương trình đi kèm phần mềm học tập tại nhà cho học sinh.",
      "Chương trình dạy phát âm đi kèm trong mỗi bài học.",
      "Bài học được thiết kế theo mô hình dự án, học viên làm việc độc lập hoặc theo nhóm để hoàn thành dự án của mình.",
      "Chương trình lồng ghép các nội dung học về kỹ năng sống, hoạt động thủ công, văn học, khoa học… giúp học viên phát triển kỹ năng ngôn ngữ song song với những kỹ năng mềm của thế kỷ 21.",
      "Các bài học về cảm xúc, giá trị đạo đức cũng được lồng ghép để học sinh phát triển trí thông minh cảm xúc.",
      "Học sinh học được giá trị của sự đoàn kết và tinh thần đồng đội thông qua các hoạt động dự án.",
      "Kỹ năng thuyết trình, làm việc nhóm được đặc biệt chú trọng trong chương trình này.",
      "Các bài luyện tập theo định hướng bài thi Cambridge được thiết kế trong mỗi đơn vị bài học tạo nền tảng để học sinh tham gia thi các kỳ thi quốc tế sau này.",
    ],
    Table: PrimaryAdvancedTable,
  },
  "luyen-thi": {
    bullet: "/images/exam-training.png",
    icon: "/images/exam-training-icon.png",
    image1: "https://i.postimg.cc/qqjtpN3f/exam-training-image-1.jpg",
    image2: "https://i.postimg.cc/qMvhjxPp/exam-training-image-2.jpg",
    courseName: "Tiếng Anh luyện thi",
    courseDescription: [
      "Chương trình dành cho học sinh trong độ tuổi 6-18 có nguyện vọng thi lấy chứng chỉ Cambridge Quốc tế Young Learners (Starters, Movers, Flyers) hoặc chứng chỉ Cambridge Quốc tế A2-B1 (KET, PET) hoặc IELTS.",
      "Học viên tham gia đánh giá năng lực và được xếp lớp học có trình độ phù hợp.",
      "Có lớp học cấp tốc dành cho học viên đã có kiến thức nền vững chắc.",
      "Sĩ số lớp: 12-20 học viên",
      "Giáo viên Việt Nam 100%",
    ],
    sellingPoints: [
      "Cam kết đầu ra đối với học viên học theo đúng lộ trình quy định bởi trung tâm.",
      "Học sinh được ôn tập với giáo viên Việt Nam là giám khảo của Cambridge hoặc giáo viên đã được tham gia tập huấn các tiêu chí chấm thi kỹ năng nói và viết cùng với giám khảo Cambridge.",
      "Ôn đúng trọng tâm. Chương trình học cung cấp đầy đủ các kiến thức và hướng dẫn chi tiết về định dạng bài thi giúp học viên tự tin khi tham gia bài thi thực tế.",
      "Học sinh được hướng dẫn kỹ năng làm bài chi tiết cho kỹ năng đọc và viết bám sát theo tiêu chí chấm điểm của Cambridge để có thể đạt được điểm số cao nhất.",
      "Học sinh được tham gia bài thi thử được tổ chức như bài thi thực tế giúp các em trải nghiệm và không còn bỡ ngỡ khi tham gia bài thi thực tế.",
    ],
    Table: ExamTrainingTable,
  },
};

function CourseDescription({ bullet, image, courseId, courseDescription }) {
  const navigate = useNavigate();

  return (
    <Stack spacing={4}>
      <Stack direction="row" justifyContent="center">
        <Typography
          className="highlight"
          variant="h2"
          color="info.main"
          align="center"
        >
          Mô tả về khoá học
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        useFlexGap
        flexWrap="wrap"
        spacing={4}
        padding={2}
      >
        <img
          src={image}
          alt={courseId}
          loading="lazy"
          width={350}
          height={350}
          style={{ borderRadius: 30 }}
        />

        <Stack alignItems="center" width={750} spacing={8}>
          <Stack spacing={2}>
            {courseDescription.map((detail) => (
              <Stack
                key={detail}
                direction="row"
                alignItems="center"
                spacing={2}
              >
                <img src={bullet} alt={`${courseId}-bullet-point`} width={35} />
                <Typography variant="body1" color="info.main">
                  {detail}
                </Typography>
              </Stack>
            ))}
          </Stack>

          <Button
            variant="contained"
            size="large"
            onClick={() => navigate("/lien-he")}
          >
            ĐĂNG KÍ NGAY
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}

function SellingPoints({ icon, image, courseId, sellingPoints }) {
  const upLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  return (
    <Stack
      spacing={4}
      padding={4}
      sx={{
        backgroundImage: 'url("/images/transparent-background.png")',
        backgroundRepeat: "repeat",
        backgroundSize: "auto 1000px",
        backgroundPositionY: "top",
      }}
    >
      <Stack direction="row" justifyContent="center">
        <Typography
          className="highlight"
          variant="h2"
          color="info.main"
          align="center"
        >
          Điểm nổi bật
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        useFlexGap
        flexWrap="wrap"
        spacing={2}
      >
        <Card
          elevation={0}
          sx={{
            position: "relative",
            borderColor: "primary.main",
            borderStyle: "dashed",
            borderRadius: 4,
            padding: 6,
            paddingRight: 8,
            marginRight: upLg ? -8 : 0,
            marginBottom: upLg ? 0 : -8,
          }}
        >
          <CardContent>
            <Stack spacing={2} sx={{ maxWidth: 650 }}>
              {sellingPoints.map((sellingPoint) => (
                <Typography
                  key={sellingPoint}
                  variant="body1"
                  color="info.main"
                >
                  {sellingPoint}
                </Typography>
              ))}
            </Stack>
          </CardContent>

          <img
            src={icon}
            className="pulse"
            alt={`${courseId}-icon`}
            width={120}
            style={{ position: "absolute", bottom: -4, right: -4, zIndex: 2 }}
          />
        </Card>

        <img
          src={image}
          alt={courseId}
          loading="lazy"
          width={320}
          height={320}
          style={{ borderRadius: 30, zIndex: 1 }}
        />
      </Stack>
    </Stack>
  );
}

function CoursePage() {
  const { courseId } = useParams();

  const {
    bullet,
    icon,
    image1,
    image2,
    courseName,
    courseDescription,
    sellingPoints,
    Table,
  } = courses[courseId];

  return (
    <Stack spacing={10}>
      <Helmet>
        <title>Chương trình {courseName}</title>
        <meta
          name="description"
          content="The English Tree đưa ra các khoá học bám sát theo sự phát triển của bé, với lộ trình đầu ra chuẩn quốc tế."
        />
        <link
          rel="canonical"
          href={`https://englishtree.edu.vn/khoa-hoc/${courseId}`}
        />
      </Helmet>

      <PageBanner
        backgroundImage="/images/pink-banner.png"
        heading={courseName}
        pageName={courseName}
      />

      <CourseDescription
        bullet={bullet}
        image={image1}
        courseId={courseId}
        courseDescription={courseDescription}
      />

      <SellingPoints
        icon={icon}
        image={image2}
        courseId={courseId}
        sellingPoints={sellingPoints}
      />

      <Table />
    </Stack>
  );
}

export default CoursePage;
