import React from "react";

import CourseTable from "components/pages/CoursePage/CourseTable";

const headers = [
  [
    {
      rowSpan: 1,
      content: "STT",
    },
    {
      rowSpan: 1,
      content: "Khoá",
    },
    {
      rowSpan: 1,
      content: "Tên CT",
    },
    {
      rowSpan: 1,
      content: "Số giờ",
    },
    {
      rowSpan: 1,
      content: "Số buổi",
    },
    {
      rowSpan: 1,
      content: "Số tuần",
    },
    {
      rowSpan: 1,
      content: "Số tháng",
    },
    {
      rowSpan: 1,
      content: "Tuổi",
    },
    {
      rowSpan: 1,
      content: "Giáo trình",
    },
    {
      rowSpan: 1,
      content: "Ghi chú",
    },
  ],
];

const rows = [
  [
    1,
    {
      rowSpan: 15,
      content: "ROOT",
    },
    "R1A",
    40.5,
    27,
    13.5,
    3.5,
    {
      rowSpan: 15,
      content: "6-11",
    },
    "FAMILY AND FRIENDS 1",
    {
      rowSpan: 15,
      content: "Theo chương trình học ở trường, bộ giáo trình có 6 cuốn",
    },
  ],
  [2, "R1B", 40.5, 27, 13.5, 3.5, "FAMILY AND FRIENDS 1"],
  [3, "R1C", 40.5, 27, 13.5, 3.5, "EVERYONE SPEAKS KIDS"],
  [4, "R2A", 40.5, 27, 13.5, 3.5, "FAMILY AND FRIENDS 2"],
  [5, "R2B", 40.5, 27, 13.5, 3.5, "FAMILY AND FRIENDS 2"],
  [6, "R2C", 40.5, 27, 13.5, 3.5, "EVERYONE SPEAKS KIDS"],
  [7, "R3A", 40.5, 27, 13.5, 3.5, "FAMILY AND FRIENDS 3"],
  [8, "R3B", 40.5, 27, 13.5, 3.5, "FAMILY AND FRIENDS 3"],
  [9, "R3C", 40.5, 27, 13.5, 3.5, "EVERYONE SPEAKS KIDS"],
  [10, "R4A", 40.5, 27, 13.5, 3.5, "FAMILY AND FRIENDS 4"],
  [11, "R4B", 40.5, 27, 13.5, 3.5, "FAMILY AND FRIENDS 4"],
  [12, "R4C", 40.5, 27, 13.5, 3.5, "EVERYONE SPEAKS KIDS"],
  [13, "R5A", 40.5, 27, 13.5, 3.5, "FAMILY AND FRIENDS 5"],
  [14, "R5B", 40.5, 27, 13.5, 3.5, "FAMILY AND FRIENDS 5"],
  [15, "R5C", 40.5, 27, 13.5, 3.5, "BỒI DƯỠNG HỌC SINH VÀO LỚP 6"],
  [
    16,
    {
      rowSpan: 12,
      content: "TREE",
    },
    "T1A",
    40.5,
    27,
    13.5,
    3.5,
    {
      rowSpan: 12,
      content: "11-16",
    },
    "TIẾNG ANH 6 (KẾT NỐI TRI THỨC)",
    {
      rowSpan: 12,
      content:
        "Học theo chương trình ở trường, dành cho học sinh đi từ khoá cơ bản lên, hoặc học sinh đã học lớp 6 từ bên ngoài vào. Nếu học sinh muốn thi chứng chỉ KET (vào cuối lớp 8) thì cần học thêm khoá luyện thi KET 1,2 và 3",
    },
  ],
  [17, "T1B", 40.5, 27, 13.5, 3.5, "TIẾNG ANH 6 (KẾT NỐI TRI THỨC)"],
  [18, "T1C", 40.5, 27, 13.5, 3.5, "EVERYONE SPEAKS BEGINNER"],
  [19, "T2A", 40.5, 27, 13.5, 3.5, "TIẾNG ANH 7 (KẾT NỐI TRI THỨC)"],
  [20, "T2B", 40.5, 27, 13.5, 3.5, "TIẾNG ANH 7 (KẾT NỐI TRI THỨC)"],
  [21, "T2C", 40.5, 27, 13.5, 3.5, "EVERYONE SPEAKS BEGINNER"],
  [22, "T3A", 40.5, 27, 13.5, 3.5, "TIẾNG ANH 8 (KẾT NỐI TRI THỨC)"],
  [23, "T3B", 40.5, 27, 13.5, 3.5, "TIẾNG ANH 8 (KẾT NỐI TRI THỨC)"],
  [24, "T3C", 40.5, 27, 13.5, 3.5, "EVERYONE SPEAKS BEGINNER"],
  [25, "T4A", 40.5, 27, 13.5, 3.5, "TIẾNG ANH 9 (KẾT NỐI TRI THỨC)"],
  [26, "T4B", 40.5, 27, 13.5, 3.5, "TIẾNG ANH 9 (KẾT NỐI TRI THỨC)"],
  [27, "T4C", 40.5, 27, 13.5, 3.5, "BỒI DƯỠNG HỌC SINH VÀO LỚP 10"],
];

function PrimaryBasicTable() {
  return (
    <CourseTable
      headerBackgroundColor="secondary.light"
      headers={headers}
      rows={rows}
    />
  );
}

export default PrimaryBasicTable;
