import React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

function TenetTab({
  icon,
  borderColor,
  backgroundColor,
  textLine1,
  textLine2,
  tenet,
  selectedTenet,
  setSelectedTenet,
}) {
  const upSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const selected = tenet === selectedTenet;

  const selectedStyle = {
    backgroundColor,
    borderColor: "transparent",
    h6: {
      color: "common.white",
    },
  };

  return (
    <Card
      elevation={0}
      sx={{
        borderColor,
        borderStyle: "dashed",
        borderRadius: 4,
        ...(selected && selectedStyle),
        "&:hover": {
          cursor: "pointer",
          ...selectedStyle,
        },
        "&:hover h6": {
          color: "common.white",
        },
      }}
      onClick={() => setSelectedTenet(tenet)}
    >
      <CardContent>
        <Stack alignItems="center" width={upSm ? 150 : 80} spacing={2}>
          <img src={icon} className="pulse" alt={tenet} height={70} />
          <Stack alignItems="center">
            <Typography variant="h6" color="info.main">
              {textLine1}
            </Typography>
            <Typography variant="h6" color="info.main">
              {textLine2}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default TenetTab;
