import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

import Album from "components/pages/HomePage/Album";
import Courses from "components/pages/HomePage/Courses";
import EarlyEducation from "components/pages/HomePage/EarlyEducation";
import HeroBanner from "components/pages/HomePage/HeroBanner";
import Schedule from "components/pages/HomePage/Schedule";
import Stack from "@mui/material/Stack";
import Testimonials from "components/pages/HomePage/Testimonials";
import Trial from "components/pages/HomePage/Trial";
import WhyUs from "components/pages/HomePage/WhyUs";

function HomePage({ news, schedule }) {
  return (
    <Stack spacing={10}>
      <Helmet>
        <title>Trang chủ - The English Tree</title>
        <meta
          name="description"
          content="Chương trình tiếng Anh toàn diện, chú trọng 4 kỹ năng NGHE - NÓI - ĐỌC - VIẾT và phát triển song song nhiều KỸ NĂNG MỀM giúp trẻ tự tin – năng động."
        />
        <link rel="canonical" href="https://englishtree.edu.vn" />
      </Helmet>

      <HeroBanner news={news} />
      <Schedule schedule={schedule} />
      <Courses />
      <EarlyEducation />
      <WhyUs />
      <Album />
      <Testimonials />
      <Trial />
    </Stack>
  );
}

HomePage.propTypes = {
  news: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    }),
  ).isRequired,
  schedule: PropTypes.arrayOf(
    PropTypes.shape({
      term: PropTypes.string.isRequired,
      branch: PropTypes.string.isRequired,
      program: PropTypes.string.isRequired,
      clazz: PropTypes.string.isRequired,
      age: PropTypes.string.isRequired,
      day: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired,
      duration: PropTypes.string.isRequired,
      start: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default HomePage;
