import React from "react";
import { useNavigate } from "react-router-dom";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useDimensions } from "hooks";

function PageBanner({ backgroundImage, heading, middleLinks, pageName }) {
  const navigate = useNavigate();
  const { screenWidth } = useDimensions();

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      height={screenWidth / 6.7}
      minHeight={200}
      maxHeight={300}
      sx={{
        backgroundImage: `url("${backgroundImage}")`,
        backgroundSize: "cover",
        backgroundPositionY: "bottom",
      }}
    >
      <Stack alignItems="center" spacing={1}>
        <Typography variant="h1" color="info.main" align="center">
          {heading}
        </Typography>

        <Breadcrumbs separator="•">
          <Link
            underline="hover"
            color="info.main"
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          >
            Trang chủ
          </Link>
          {middleLinks}
          <Typography color="info.main">{pageName}</Typography>
        </Breadcrumbs>
      </Stack>
    </Stack>
  );
}

export default PageBanner;
