import React, { useState } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import TenetContent from "components/pages/AboutUs/Tenets/TenetContent";
import TenetTab from "components/pages/AboutUs/Tenets/TenetTab";
import Typography from "@mui/material/Typography";

function TenetContentVision() {
  return (
    <TenetContent
      image="https://i.postimg.cc/Dwzr4Tvd/vision-image.jpg"
      borderColor="primary.main"
      tenet="Mục tiêu - Tầm nhìn"
    >
      <Typography variant="body1" color="info.main">
        The English Tree mong muốn mang đến chương trình tiếng Anh có chi phí
        hợp lý nhất nhưng có chất lượng đào tạo vượt trội và uy tín hàng đầu khu
        vực.
      </Typography>
      <Typography variant="body1" color="info.main">
        Lấy phương châm giáo dục toàn diện “holistic learning” làm kim chỉ nam,
        The English Tree mong muốn đào tạo ra thế hệ công dân không chỉ giỏi
        tiếng Anh mà còn thành thục những kỹ năng mềm của thế kỷ 21.
      </Typography>
    </TenetContent>
  );
}

function TenetContentPhilosophy() {
  return (
    <TenetContent
      image="https://i.postimg.cc/VNrX4WbJ/philosophy-image.jpg"
      borderColor="secondary.main"
      tenet="Triết lý giáo dục"
    >
      <Typography variant="body1" color="info.main">
        Phương châm giáo dục của chúng tôi xoay quanh chữ “holistic” – toàn
        diện.
      </Typography>

      <Stack spacing={2}>
        {[
          "Tiếng Anh toàn diện Nghe-Nói-Đọc-Viết-Ngữ pháp",
          "Phát triển toàn diện khả năng ngôn ngữ và kỹ năng mềm thế kỷ 21",
          "Chăm sóc toàn diện học sinh và phụ huynh",
          "Đầu tư toàn diện giáo trình – cơ sở vật chất – đội ngũ giáo viên – phần mềm giảng dạy",
        ].map((item) => (
          <Stack key={item} direction="row" alignItems="center" spacing={2}>
            <img src="/images/philosophy.png" alt="philosophy" width={20} />
            <Typography variant="body1" color="info.main">
              {item}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </TenetContent>
  );
}

function TenetContentValues() {
  return (
    <TenetContent
      image="https://i.postimg.cc/xCLMVj5Y/values-image.jpg"
      borderColor="tertiary.blue.main"
      tenet="Giá trị cốt lõi"
    >
      <Typography variant="body1" color="info.main">
        Để trở thành người đồng hành đáng tin cậy của mọi phụ huynh và môi
        trường làm việc lý tưởng cho thế hệ trẻ, chúng tôi đã xây dựng văn hoá
        làm việc mà ở đó những giá trị sau luôn được đặt lên hàng đầu:
      </Typography>

      <List>
        {[
          {
            primary: "Trung thực",
            secondary:
              "Chúng tôi coi trọng lời nói và những cam kết giữa các bên. Nói phải đi đôi với làm, không che giấu, nguỵ biện.",
          },
          {
            primary: "Tận tâm",
            secondary:
              "Tất cả giáo viên, nhân viên của The English Tree cam kết luôn hết mình vì công việc và hỗ trợ học sinh, phụ huynh tối đa.",
          },
          {
            primary: "Chuyên nghiệp",
            secondary:
              "Tất cả giáo viên, nhân viên của The English Tree đều được đào tạo và tập huấn nghiệp vụ bài bản. Các quy trình và hệ thống quản lý được xây dựng rõ ràng.",
          },
          {
            primary: "Hiệu quả",
            secondary:
              "Phương pháp làm việc khoa học và tinh thần tập trung cao độ luôn được đề cao để chúng tôi hoàn thành các nhiệm vụ và mục tiêu đề ra với hiệu quả cao nhất.",
          },
          {
            primary: "Chia sẻ, cởi mở",
            secondary:
              "Chúng tôi lắng nghe và tạo điều kiện cho tất cả thành viên của The English Tree được chia sẻ quan điểm, khó khăn và học hỏi kinh nghiệm lẫn nhau.",
          },
          {
            primary: "Sáng tạo, đổi mới",
            secondary:
              "Đổi mới tư duy làm việc, sáng tạo trong cách thức hoạt động, sáng tạo trong chương trình và phương pháp giảng dạy là những yếu tố luôn được thúc đẩy tại The English Tree.",
          },
        ].map(({ primary, secondary }) => (
          <ListItem key={primary}>
            <ListItemText
              primary={primary}
              secondary={secondary}
              primaryTypographyProps={{ fontWeight: "bold" }}
              secondaryTypographyProps={{ color: "info.main" }}
            />
          </ListItem>
        ))}
      </List>
    </TenetContent>
  );
}

function Tenets() {
  const [selectedTenet, setSelectedTenet] = useState("vision");

  return (
    <Stack
      spacing={4}
      sx={{
        backgroundImage: 'url("/images/transparent-background.png")',
        backgroundRepeat: "repeat",
        backgroundSize: "auto 1000px",
        backgroundPositionY: "top",
      }}
    >
      <Stack direction="row" justifyContent="center">
        <Typography
          className="highlight"
          variant="h2"
          color="info.main"
          align="center"
        >
          Đường hướng hoạt động
        </Typography>
      </Stack>

      <Stack direction="row" justifyContent="center" spacing={2}>
        <TenetTab
          icon="/images/vision.png"
          borderColor="primary.main"
          backgroundColor="primary.light"
          textLine1="Mục tiêu"
          textLine2="Tầm nhìn"
          tenet="vision"
          selectedTenet={selectedTenet}
          setSelectedTenet={setSelectedTenet}
        />
        <TenetTab
          icon="/images/philosophy.png"
          borderColor="secondary.main"
          backgroundColor="secondary.light"
          textLine1="Triết lý"
          textLine2="giáo dục"
          tenet="philosophy"
          selectedTenet={selectedTenet}
          setSelectedTenet={setSelectedTenet}
        />
        <TenetTab
          icon="/images/values.png"
          borderColor="tertiary.blue.main"
          backgroundColor="tertiary.blue.light"
          textLine1="Giá trị"
          textLine2="cốt lõi"
          tenet="values"
          selectedTenet={selectedTenet}
          setSelectedTenet={setSelectedTenet}
        />
      </Stack>

      {selectedTenet === "vision" && <TenetContentVision />}
      {selectedTenet === "philosophy" && <TenetContentPhilosophy />}
      {selectedTenet === "values" && <TenetContentValues />}
    </Stack>
  );
}

export default Tenets;
