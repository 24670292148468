import React, { useRef, useState } from "react";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

function SubmissionDialog({ title, content, onClose }) {
  return (
    <Dialog
      open={true}
      onClose={onClose}
      PaperComponent={(props) => (
        <Paper {...props} sx={{ borderRadius: 2, padding: 4 }} />
      )}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

function SubmissionSuccessDialog({ onClose }) {
  return (
    <SubmissionDialog
      title="Thông tin đã được gửi"
      content="Cám ơn quý phụ huynh đã gửi thông tin. Chúng tôi sẽ liên lạc để sắp xếp khoá học cho bé."
      onClose={onClose}
    />
  );
}

function SubmissionFailureDialog({ onClose }) {
  return (
    <SubmissionDialog
      title="Thông tin chưa được gửi"
      content="Xin vui lòng thử lại."
      onClose={onClose}
    />
  );
}

function ContactForm() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [
    shouldShowSubmissionSuccessDialog,
    setShouldShowSubmissionSuccessDialog,
  ] = useState(false);
  const [
    shouldShowSubmissionFailureDialog,
    setShouldShowSubmissionFailureDialog,
  ] = useState(false);

  const formRef = useRef(null);

  const upMd = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const submitForm = (event) => {
    setIsSubmitting(true);

    const request = new XMLHttpRequest();

    request.open("POST", "https://api.web3forms.com/submit", true);
    request.onload = () => {
      formRef.current.reset();
      setIsSubmitting(false);
      setShouldShowSubmissionSuccessDialog(true);
    };
    request.onerror = () => {
      setIsSubmitting(false);
      setShouldShowSubmissionFailureDialog(true);
    };

    request.send(new FormData(event.target));

    event.preventDefault();
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        useFlexGap
        flexWrap="wrap"
      >
        <img
          src="https://i.postimg.cc/6qLrn64z/try-out-course.jpg"
          alt="try-out-course"
          loading="lazy"
          width={345}
          style={{ borderRadius: 30, zIndex: 1 }}
        />

        <Card
          elevation={0}
          sx={{
            backgroundColor: "tertiary.pink.light",
            borderRadius: 4,
            padding: "60px 60px 30px",
            width: 400,
            marginLeft: upMd ? -8 : 0,
            marginTop: upMd ? 0 : -8,
          }}
        >
          <form ref={formRef} onSubmit={submitForm}>
            <input
              type="hidden"
              name="access_key"
              value="01605c18-ba1a-43a0-8b15-1a05f09b446c"
            />
            <input type="hidden" name="subject" value="Trial submission form" />
            <input type="hidden" name="from_name" value="English Tree" />
            <input
              type="checkbox"
              name="botcheck"
              style={{ display: "none" }}
            />

            <CardContent>
              <Stack spacing={2}>
                <Typography
                  variant="h6"
                  color="common.white"
                  align="center"
                  gutterBottom
                >
                  Hot line: 0966 766 839 - 0274 7306839
                </Typography>
                <TextField
                  required
                  name="Họ và tên của bé"
                  placeholder="Họ và tên của bé"
                  sx={{ backgroundColor: "common.white", borderRadius: 1 }}
                  onInvalid={(event) =>
                    event.target.setCustomValidity("Vui lòng nhập thông tin.")
                  }
                  onInput={(event) => event.target.setCustomValidity("")}
                />
                <TextField
                  required
                  type="number"
                  name="Tuổi của bé"
                  placeholder="Tuổi của bé"
                  sx={{ backgroundColor: "common.white", borderRadius: 1 }}
                  onInvalid={(event) =>
                    event.target.setCustomValidity("Vui lòng nhập số tuổi.")
                  }
                  onInput={(event) => event.target.setCustomValidity("")}
                />
                <TextField
                  required
                  type="email"
                  name="Địa chỉ email của bạn"
                  placeholder="Địa chỉ email của bạn"
                  sx={{ backgroundColor: "common.white", borderRadius: 1 }}
                  onInvalid={(event) =>
                    event.target.setCustomValidity("Vui lòng nhập email.")
                  }
                  onInput={(event) => event.target.setCustomValidity("")}
                />
                <TextField
                  required
                  type="tel"
                  name="Số điện thoại của bạn"
                  placeholder="Số điện thoại của bạn"
                  sx={{ backgroundColor: "common.white", borderRadius: 1 }}
                  onInvalid={(event) =>
                    event.target.setCustomValidity(
                      "Vui lòng nhập số điện thoại.",
                    )
                  }
                  onInput={(event) => event.target.setCustomValidity("")}
                />
                <TextField
                  required
                  name="Nhu cầu tư vấn"
                  placeholder="Nhu cầu tư vấn"
                  multiline
                  rows={3}
                  sx={{ backgroundColor: "common.white", borderRadius: 1 }}
                  onInvalid={(event) =>
                    event.target.setCustomValidity("Vui lòng nhập thông tin.")
                  }
                  onInput={(event) => event.target.setCustomValidity("")}
                />
              </Stack>
            </CardContent>

            <CardActions>
              <Stack
                direction="row"
                alignItems="flex-end"
                justifyContent="space-between"
                width="100%"
              >
                {isSubmitting && <CircularProgress />}
                {!isSubmitting && (
                  <Button
                    variant="outlined"
                    size="large"
                    type="submit"
                    sx={{
                      color: "common.white",
                      borderColor: "common.white",
                      "&:hover": {
                        borderColor: "common.white",
                      },
                    }}
                  >
                    Gửi thông tin
                  </Button>
                )}
                <img
                  src="/images/a-plus.png"
                  className="pulse"
                  alt="a-plus"
                  width={120}
                />
              </Stack>
            </CardActions>
          </form>
        </Card>
      </Stack>

      {shouldShowSubmissionSuccessDialog && (
        <SubmissionSuccessDialog
          onClose={() => setShouldShowSubmissionSuccessDialog(false)}
        />
      )}
      {shouldShowSubmissionFailureDialog && (
        <SubmissionFailureDialog
          onClose={() => setShouldShowSubmissionFailureDialog(false)}
        />
      )}
    </>
  );
}

export default ContactForm;
