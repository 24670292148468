import { useEffect, useState } from "react";

const initialDevicePixelRatio = window.devicePixelRatio;
const heroBannerHeightToScreenWidthRatio = 0.5;

export function useDimensions() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      if (initialDevicePixelRatio === window.devicePixelRatio) {
        setScreenWidth(window.innerWidth);
      }
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return {
    screenWidth,
    heroBannerHeight: screenWidth * heroBannerHeightToScreenWidthRatio,
  };
}
