import React from "react";
import { Helmet } from "react-helmet-async";

import Banner from "components/pages/Promotion/Banner";
import Benefit from "components/pages/Promotion/Benefit";
import Stack from "@mui/material/Stack";

const benefits = [
  {
    id: "benefit-1",
    imgSrc: "https://i.postimg.cc/VLQrjsXm/benefit-image-1.png",
    header: "TIẾP XÚC 1:1 VỚI GIÁO VIÊN BẢN XỨ",
    text: "Tập trung tăng tương tác ngoại ngữ tối đa và phát triển tư duy Tiếng Anh tự nhiên, trẻ được học và dẫn dắt bởi bởi đội ngũ giáo viên nước ngoài thân thiện, giàu kinh nghiệm chuyên môn, có bằng cấp chính quy và luôn yêu mến trẻ.",
  },
  {
    id: "benefit-2",
    imgSrc: "https://i.postimg.cc/tJPsL8QK/benefit-image-2.png",
    header: "HỌC THỬ 2 BUỔI ĐẦU TIÊN MIỄN PHÍ",
    text: "The English Tree miễn phí 2 buổi học đầu tiên cho trẻ và ba mẹ trải nghiệm môi trường học Anh ngữ sáng tạo hoàn toàn mới 100% với phương pháp giáo dục Tiếng Anh tự nhiên.",
  },
  {
    id: "benefit-3",
    imgSrc: "https://i.postimg.cc/hjXz0fCP/benefit-image-3.png",
    header: "TRẢI NGHIỆM LỚP HỌC NGOẠI KHÓA",
    text: "Mang đến môi trường học sáng tạo: lồng ghép Anh ngữ vào các hoạt động học tập - vui chơi - trải nghiệm, giúp trẻ kết nối Tiếng Anh với thế giới xung quanh và chủ động sử dụng ngoại ngữ.",
  },
  {
    id: "benefit-4",
    imgSrc: "https://i.postimg.cc/0Qh6XP3G/benefit-image-4.png",
    header: 'COMBO QUÀ TẶNG "VUI HỌC - HỌC VUI"',
    text: "Dành tặng bé combo quà xinh xắn: balo, áo thun đồng phục, ... khi đăng ký nhập học tại The English Tree.",
  },
  {
    id: "benefit-5",
    imgSrc: "https://i.postimg.cc/3rD0404t/benefit-image-5.png",
    header: "CƠ SỞ VẬT CHẤT TIỆN NGHI, HIỆN ĐẠI",
    text: "Cơ sở vật chất hiện đại, được trang bị đầy đủ công nghệ dạy và học. Ngoài ra The English Tree còn trang bị cho phụ huynh phần mềm điện thoại “Easy Edu”, giữ liên lạc giữa gia đình và nhà trường để theo dõi tiến trình học tập của trẻ.",
  },
];

function Image({ index, imgSrc, alt }) {
  const even = index % 2 === 0;
  const color = even
    ? "#37b995" // primary.main
    : "#f37820"; // secondary.main

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: -25,
          left: -25,
          width: 50,
          height: 50,
          borderRadius: "50%",
          backgroundColor: color,
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 30,
        }}
      >
        {index + 1}
      </div>
      <div
        style={{
          position: "absolute",
          width: 350,
          height: 250,
          borderRadius: 30,
          border: `2px dashed ${color}`,
          transform: `rotate(${even ? -5 : 5}deg)`,
        }}
      />
      <img
        src={imgSrc}
        alt={alt}
        loading="lazy"
        width={350}
        height={250}
        style={{ borderRadius: 30 }}
      />
    </div>
  );
}

function Promotion() {
  return (
    <Stack spacing={10}>
      <Helmet>
        <title>Ưu đãi vàng</title>
        <meta
          name="description"
          content="Trao ưu đãi vàng học Tiếng Anh không lo chi phí"
        />
        <link
          rel="canonical"
          href="https://englishtree.edu.vn/tin-tuc/uu-dai-vang"
        />
      </Helmet>

      <Banner />

      {benefits.map(({ id, imgSrc, header, text }, index) => (
        <Benefit
          key={id}
          index={index}
          img={<Image index={index} imgSrc={imgSrc} alt={id} />}
          header={header}
          text={text}
        />
      ))}
    </Stack>
  );
}

export default Promotion;
