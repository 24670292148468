import React from "react";

import CollapsedNavBar from "components/NavBar/CollapsedNavBar";
import FullNavBar from "components/NavBar/FullNavBar";
import useMediaQuery from "@mui/material/useMediaQuery";

function NavBar() {
  const upMd = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const courses = [
    {
      title: "Mầm non Kindy",
      path: "/khoa-hoc/kindy",
    },
    {
      title: "Tiếng Anh phổ thông",
      path: "/khoa-hoc/pho-thong",
    },
    {
      title: "Tiếng Anh quốc tế",
      path: "/khoa-hoc/quoc-te",
    },
    {
      title: "Tiếng Anh luyện thi",
      path: "/khoa-hoc/luyen-thi",
    },
  ];

  const whyUs = [
    {
      title: "40% công thức bí mật",
      path: "/diem-dac-biet?ddb=cong-thuc-bi-mat",
    },
    {
      title: "20% nguyên liệu chất lượng",
      path: "/diem-dac-biet?ddb=nguyen-lieu-chat-luong",
    },
    {
      title: "20% chuyên gia tay nghề cao",
      path: "/diem-dac-biet?ddb=chuyen-gia-tay-nghe-cao",
    },
    {
      title: "10% trang trí hài hoà",
      path: "/diem-dac-biet?ddb=trang-tri-hai-hoa",
    },
    {
      title: "10% phục vụ chu đáo",
      path: "/diem-dac-biet?ddb=phuc-vu-chu-dao",
    },
  ];

  const company = [
    {
      title: "Về chúng tôi",
      path: "/ve-chung-toi",
    },
    {
      title: "Tuyển dụng",
      path: "/tuyen-dung",
    },
  ];

  const news = [
    {
      title: "Ưu đãi vàng",
      path: "/tin-tuc/uu-dai-vang",
    },
  ];

  const library = [
    {
      title: "Blog",
      path: "/blog",
    },
    {
      title: "Tài liệu",
      path: "/tai-lieu",
    },
  ];

  if (upMd) {
    return (
      <FullNavBar
        courses={courses}
        whyUs={whyUs}
        company={company}
        news={news}
        library={library}
      />
    );
  }

  return (
    <CollapsedNavBar
      courses={courses}
      whyUs={whyUs}
      company={company}
      news={news}
      library={library}
    />
  );
}

export default NavBar;
