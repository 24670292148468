import React from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

function HeaderText({ children }) {
  return (
    <Typography variant="body1" color="common.white" fontWeight="bold">
      {children}
    </Typography>
  );
}

function CustomTable({ headerBackgroundColor, headers, rows }) {
  return (
    <TableContainer component={Paper} sx={{ maxWidth: 1000 }}>
      <Table sx={{ maxWidth: 1000 }}>
        <TableHead>
          {headers.map((header, index) => (
            <TableRow
              key={index}
              sx={{ backgroundColor: headerBackgroundColor }}
            >
              {header.map((cell, index) =>
                typeof cell === "object" ? (
                  <TableCell key={`${cell.content}-${index}`} {...cell}>
                    <HeaderText>{cell.content}</HeaderText>
                  </TableCell>
                ) : (
                  <TableCell key={`${cell}-${index}`}>
                    <HeaderText>{cell}</HeaderText>
                  </TableCell>
                )
              )}
            </TableRow>
          ))}
        </TableHead>

        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              {row.map((cell, index) =>
                typeof cell === "object" ? (
                  <TableCell key={`${cell.content}-${index}`} {...cell}>
                    {cell.content}
                  </TableCell>
                ) : (
                  <TableCell key={`${cell}-${index}`}>{cell}</TableCell>
                )
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CustomTable;
