import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function NameMeaning() {
  return (
    <Stack spacing={4}>
      <Stack direction="row" justifyContent="center">
        <Typography
          className="highlight"
          variant="h2"
          color="info.main"
          align="center"
        >
          Ý nghĩa tên gọi
        </Typography>
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        useFlexGap
        flexWrap="wrap"
        spacing={4}
        padding={2}
      >
        <img
          src="https://i.postimg.cc/1XHF4Wbj/name-meaning.jpg"
          alt="name-meaning"
          loading="lazy"
          width={350}
          height={350}
          style={{ borderRadius: 30 }}
        />

        <Stack width={750} spacing={2}>
          <Typography variant="body1" color="info.main">
            Học tiếng Anh cũng như chăm sóc cây cỏ, cần có tình yêu và sự nhẫn
            nại. Hãy gieo vào lòng đất hạt mầm chính là niềm yêu thích với bộ
            môn tiếng Anh và chăm sóc nó hàng ngày bằng sự chăm chỉ và kiên trì.
            Rồi đến một ngày cây lớn sẽ cho ta quả ngọt, hoa thơm. Thành công sẽ
            đến với những ai biết đam mê và không ngừng nỗ lực.
          </Typography>
          <Typography variant="body1" color="info.main">
            Slogan “Anh ngữ toàn diện cho trẻ em”: tại Anh ngữ Quốc tế The
            English Tree, chúng tôi thiết kế chương trình giáo dục chuyên biệt
            để đào tạo và giúp các bé hoàn thiện bản thân một cách toàn diện cả
            về mặt ngôn ngữ Anh lẫn những kỹ năng mềm thiết yếu của thế kỷ 21.
          </Typography>
          <Typography variant="body1" color="info.main">
            Chúng tôi mong muốn rằng trong chặng đường chinh phục tri thức và
            khám phá thế giới, những thế hệ học viên của Anh ngữ Quốc tế The
            English Tree sẽ được trang bị đầy đủ hành trang để trở thành những
            công dân toàn cầu, tự tin vững bước vươn xa.
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default NameMeaning;
