import React from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function EarlyEducation() {
  const navigate = useNavigate();

  return (
    <Stack spacing={4}>
      <Stack
        direction="row"
        justifyContent="center"
        useFlexGap
        flexWrap="wrap"
        spacing={4}
        padding={2}
      >
        <img
          src="https://i.postimg.cc/fLQSCnNP/early-education.jpg"
          alt="early-education"
          loading="lazy"
          width={350}
          height={350}
          style={{ borderRadius: 30 }}
        />

        <Stack alignItems="center" width={700} spacing={4}>
          <Typography variant="h3" color="info.main" align="center">
            Một khởi đầu sớm cho bé
          </Typography>

          <Stack spacing={2}>
            <Typography variant="body1" color="info.main">
              Hãy cho con bạn cơ hội để trở thành một công dân toàn cầu, để bé
              có thể tự do thực hiện ước mơ của mình mà không có bất cứ rào cản
              nào về ngôn ngữ. Để làm được điều này, việc cho bé tiếp xúc với
              tiếng Anh từ tuổi nhỏ là cơ hội vàng để thiết lập một nền tảng
              ngôn ngữ vững chắc, và giúp tạo dựng, phát huy sự năng động, tự
              tin của bé.
            </Typography>
            <Typography variant="body1" color="info.main">
              Hãy đến với The English Tree ngay khi có thể để chúng tôi cùng bạn
              giúp con bước lên những nấc thang mới của hành trình tri thức.
            </Typography>
          </Stack>
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate("/ve-chung-toi")}
          >
            Tìm hiểu thêm
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default EarlyEducation;
