import React from "react";
import PropTypes from "prop-types";

import "./Column.css";

function Column({
  className,
  width,
  height,
  spacing,
  padding,
  alignmentHorizontal,
  alignmentVertical,
  style,
  children,
}) {
  return (
    <div
      className={`${className} column column-spacing-${spacing} column-padding-${padding} column-alignment-horizontal-${alignmentHorizontal} column-alignment-vertical-${alignmentVertical}`}
      style={{ ...(width && { width }), ...(height && { height }), ...style }}
    >
      {children}
    </div>
  );
}

Column.propTypes = {
  className: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
  spacing: PropTypes.oneOf(["small", "medium", "large"]),
  padding: PropTypes.oneOf(["small", "medium", "large"]),
  alignmentHorizontal: PropTypes.oneOf(["left", "center", "right"]),
  alignmentVertical: PropTypes.oneOf(["top", "center", "bottom"]),
  style: PropTypes.object,
};

export default Column;
