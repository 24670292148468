import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import CustomTable from "components/common/CustomTable";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const headers = (branch) => [
  [
    {
      colSpan: 7,
      content: branch,
      sx: { textAlign: "center" },
    },
  ],
  [
    "Chương trình",
    "Lớp",
    "Độ tuổi",
    "Ngày học",
    "Thời gian học",
    "Thời lượng",
    "Ngày khai giảng",
  ],
];

function Schedule({ schedule }) {
  const [term, setTerm] = useState("");
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    if (schedule.length) {
      setTerm(schedule[0].term);
      setBranches([...new Set(schedule.map(({ branch }) => branch))]);
    }
  }, [schedule]);

  return (
    <Stack spacing={4}>
      <Stack direction="row" justifyContent="center">
        <Stack spacing={2} alignItems="center">
          <Typography className="highlight" variant="h2" color="info.main">
            Lịch khai giảng
          </Typography>
          {term ? (
            <Typography variant="h4" color="info.main">
              {term}
            </Typography>
          ) : (
            <Typography variant="h4" color="info.main">
              Đang tải...
            </Typography>
          )}
        </Stack>
      </Stack>

      {branches.map((branch) => (
        <Stack key={branch} direction="row" justifyContent="center">
          <CustomTable
            headerBackgroundColor="tertiary.yellow.main"
            headers={headers(branch)}
            rows={schedule
              .filter((item) => item.branch === branch)
              .map(({ program, clazz, age, day, time, duration, start }) => [
                program,
                clazz,
                age,
                day,
                time,
                duration,
                start,
              ])}
          />
        </Stack>
      ))}
    </Stack>
  );
}

Schedule.propTypes = {
  schedule: PropTypes.arrayOf(
    PropTypes.shape({
      term: PropTypes.string.isRequired,
      branch: PropTypes.string.isRequired,
      program: PropTypes.string.isRequired,
      clazz: PropTypes.string.isRequired,
      age: PropTypes.string.isRequired,
      day: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired,
      duration: PropTypes.string.isRequired,
      start: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Schedule;
