import React, { useEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Paper from "@mui/material/Paper";
import Paragraph from "components/common/Paragraph";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";

const CustomAccordionSummary = styled(({ icon, secret, ...rest }) => (
  <AccordionSummary
    expandIcon={<img src={icon} alt={secret} width={80} />}
    {...rest}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "none",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(4),
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    marginLeft: theme.spacing(4),
  },
}));

const ddbToSecret = {
  "cong-thuc-bi-mat": "recipe",
  "nguyen-lieu-chat-luong": "ingredients",
  "chuyen-gia-tay-nghe-cao": "expertise",
  "trang-tri-hai-hoa": "presentation",
  "phuc-vu-chu-dao": "service",
};

const secretToDdb = {
  recipe: "cong-thuc-bi-mat",
  ingredients: "nguyen-lieu-chat-luong",
  expertise: "chuyen-gia-tay-nghe-cao",
  presentation: "trang-tri-hai-hoa",
  service: "phuc-vu-chu-dao",
};

function Secret({ icon, secret, heading, children }) {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const paramSecret = ddbToSecret[searchParams.get("ddb")];

  const secretRef = useRef(null);
  const upMd = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [expanded, setExpanded] = useState(paramSecret === secret);

  const scroll = () => {
    const navHeight = upMd ? 100 : 60;

    // Wait for expansion before scroll
    setTimeout(() => {
      window.scrollTo({
        top: secretRef.current.offsetTop - navHeight,
        behavior: "smooth",
      });
    }, 200);
  };

  useEffect(() => {
    if (paramSecret === secret) {
      if (!expanded) {
        setExpanded(true);
      }
      scroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, paramSecret]);

  useEffect(() => {
    if (expanded) {
      scroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  return (
    <Accordion
      ref={secretRef}
      sx={{ padding: 2 }}
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
        !expanded && setSearchParams({ ddb: secretToDdb[secret] });
      }}
    >
      <CustomAccordionSummary icon={icon} secret={secret}>
        <Typography variant="h5" color="info.main">
          {heading}
        </Typography>
      </CustomAccordionSummary>

      <AccordionDetails>
        <Stack spacing={2} pt={2}>
          {children}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}

function Recipe() {
  return (
    <Secret
      icon="/images/recipe-icon.png"
      secret="recipe"
      heading="Công thức bí mật"
    >
      <Paragraph
        heading="Phương pháp vòng lặp (looping)"
        body="Phương pháp vòng lặp mang đến sự tiến bộ không ngừng thông qua sự lặp lại liên tục của quá trình tiếp thu kiến thức mới – củng cố nội dung cũ. Với phương pháp vòng lặp chúng tôi cam kết giúp học sinh:"
        list={[
          "Ghi nhớ kiến thức dài lâu",
          "Thông thạo ngôn ngữ một cách tự nhiên",
          "Tự tin giao tiếp trong mọi hoàn cảnh",
          "Luôn tìm tòi, phát triển bản thân để trở thành phiên bản tốt nhất của quả",
        ]}
      />
      <Paragraph
        heading="Mô hình vườn cây"
        body="Trong lớp, mỗi học sinh có nhiệm vụ chăm sóc một cây xanh đặc biệt, tượng trưng cho những nỗ lực của các em trong học tập. Sự tiến bộ của học sinh diễn ra hàng ngày, được thể hiện trực quan ngay tại lớp học thông qua hình ảnh vườn cây mà các em chăm sóc."
      />
      <Paragraph
        heading="Thêm vào đó, chúng tôi có lộ trình học khoa học với chuẩn đầu ra rõ ràng"
        list={[
          "3.5–5 tuổi: xây dựng nền tảng giao tiếp tiếng Anh và nhận biết âm",
          "5-6 tuổi: trau dồi kỹ năng giao tiếp, chuẩn bị cho kỹ năng đọc",
          "6-7 tuổi: nâng cao kỹ năng giao tiếp, học đọc và chuẩn bị viết",
          "7–12 tuổi: rèn luyện cả 4 kỹ năng, chuẩn bị cho các bài thi năng lực tiếng Anh đầu đời như Starters, Movers, Flyers, Toefl Junior…",
        ]}
      />

      <TableContainer component={(props) => <Paper {...props} elevation={0} />}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "primary.light" }}>
              {["Tuổi", "Trình độ theo khung CEFR", "Chứng chỉ Cambridge"].map(
                (header) => (
                  <TableCell
                    key={header}
                    sx={{ color: "common.white", fontWeight: "bold" }}
                  >
                    {header}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {[
              ["6-7", "Pre A1", "Starters"],
              ["8-9", "A1", "Movers"],
              [10, "A2", "Flyers"],
              [11, "A2", "KET"],
              ["12-13", "B1", "PET"],
            ].map((row) => (
              <TableRow key={row.join()}>
                {row.map((cell) => (
                  <TableCell key={cell}>{cell}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Secret>
  );
}

function Ingredients() {
  return (
    <Secret
      icon="/images/ingredients-icon.png"
      secret="ingredients"
      heading="Nguyên liệu chất lượng"
    >
      <Paragraph
        heading="Tài liệu học tập"
        body="The English Tree lựa chọn từ những nhà xuất bản uy tín đi kèm với phần mềm giảng dạy và phần mềm hỗ trợ học sinh giúp giáo viên cũng như học sinh có được trải nghiệm dạy-học tốt nhất."
      />
      <Paragraph
        heading="Nội dung học tập gắn liền thực tiễn, nhiều hoạt động học thú vị như:"
        list={[
          "Một ngày làm lớp trưởng",
          "Thử làm giáo viên",
          "Lồng tiếng hoạt hình",
          "Sáng tạo lời bài hát",
          "Diễn kịch",
          "Giải quyết tình huống",
        ]}
      />
      <Paragraph
        body="Đi kèm các nhiệm vụ là phương pháp giảng dạy tiên tiến nhất hiện nay như"
        list={[
          "Phương pháp ngôn ngữ chủ động và ngôn ngữ thụ động",
          "Phương pháp nghe tích luỹ",
          "Phương pháp tạo thói quen tích cực",
          "Phương pháp phản xạ toàn thân (Total Physical Response - TPR)",
          "Phương pháp tích hợp nội dung – ngôn ngữ (Content and Language Integrated Learning – CLIL)",
          "Phương pháp học tư duy thế kỷ 21 … tất cả sẽ giúp trẻ tiếp thu ngôn ngữ một cách tự nhiên và phát triển sự yêu thích với bộ môn tiếng Anh",
        ]}
      />
    </Secret>
  );
}

function Expertise() {
  return (
    <Secret
      icon="/images/expertise-icon.png"
      secret="expertise"
      heading="Chuyên gia tay nghề cao"
    >
      <Paragraph
        heading="Tại The English Tree, chất lượng giáo viên được đặt lên hàng đầu"
        list={[
          "100% giáo viên tại The English Tree tốt nghiệp sư phạm hoặc có chứng chỉ giảng dạy",
          "Tất cả giáo viên của The English Tree đều được tuyển dụng, đào tạo và tập huấn nghiệp vụ theo những tiêu chuẩn mà chúng tôi đưa ra.",
        ]}
      />
      <Paragraph heading="Bên cạnh đó, học sinh còn được luyện thi chứng chỉ tiếng Anh Cambridge cùng chuyên gia" />
    </Secret>
  );
}

function Presentation() {
  return (
    <Secret
      icon="/images/presentation-icon.png"
      secret="presentation"
      heading="Trang trí hài hoà"
    >
      <Paragraph
        body="Ở đây chúng tôi muốn nói đến việc hiện đại hoá hệ thống quản lý. The English Tree sử dụng phần mềm quản lý giáo dục hiện đại để:"
        list={[
          "Cập nhật tiến độ và hình ảnh học tập của học sinh",
          "Theo dõi tiến độ và kết quả làm bài ở nhà của học sinh",
          "Cung cấp bài tập bổ trợ cho mỗi bài học",
        ]}
      />
    </Secret>
  );
}

function Service() {
  return (
    <Secret
      icon="/images/service-icon.png"
      secret="service"
      heading="Phục vụ chu đáo"
    >
      <Paragraph
        heading="Chăm sóc kỹ càng từng học viên"
        body="The English Tree có sĩ số lớp thấp dao động từ 12- 18 học viên/ lớp (ngoại trừ lớp luyện thi). Đồng thời, The English Tree có giáo viên chủ nhiệm cho từng lớp nhằm theo sát và hỗ trợ học sinh kịp thời trong suốt quá trình học."
      />
      <Paragraph
        heading="Nơi phụ huynh và nhà trường cùng đồng hành"
        body="Quá trình học của con tại The English Tree luôn được giáo viên chủ nhiệm ghi lại và cập nhật hàng ngày đến phụ huynh thông qua phần mềm liên lạc."
      />

      <Paragraph
        heading="Nhiều dịch vụ miễn phí"
        list={[
          "Tài khoản luyện tập ở nhà miễn phí",
          "Thư viện đọc sách miễn phí",
          "Dò bài cũ trước 30 phút miễn phí (đối với phụ huynh có nhu cầu)",
          "Nhắc nhở làm bài tập về nhà miễn phí (đối với phụ huynh có nhu cầu)",
          "Ôn thi học kỳ chương trình chính khoá miễn phí (đối với phụ huynh có nhu cầu)",
          "Thi thử các bài thi theo định dạng chuẩn quốc tế Cambridge miễn phí",
        ]}
      />
    </Secret>
  );
}

function Secrets() {
  return (
    <Stack alignItems="center" spacing={4}>
      <Typography
        className="highlight"
        variant="h2"
        color="info.main"
        align="center"
        maxWidth={900}
        sx={{ pl: 1, pr: 1 }}
      >
        Lý do phụ huynh nên chọn The English Tree
      </Typography>

      <Stack maxWidth={900}>
        <Recipe />
        <Ingredients />
        <Expertise />
        <Presentation />
        <Service />
      </Stack>
    </Stack>
  );
}

export default Secrets;
