import React from "react";
import { Helmet } from "react-helmet-async";

import NameMeaning from "components/pages/AboutUs/NameMeaning";
import PageBanner from "components/common/PageBanner";
import Stack from "@mui/material/Stack";
import Tenets from "components/pages/AboutUs/Tenets";

function AboutUs() {
  return (
    <Stack spacing={10}>
      <Helmet>
        <title>Về chúng tôi</title>
        <meta
          name="description"
          content="The English Tree mong muốn mang đến chương trình tiếng Anh có chi phí hợp lý nhất nhưng có chất lượng đào tạo vượt trội và uy tín hàng đầu khu vực."
        />
        <link rel="canonical" href="https://englishtree.edu.vn/ve-chung-toi" />
      </Helmet>

      <PageBanner
        backgroundImage="/images/yellow-banner.png"
        heading="Về chúng tôi"
        pageName="Về chúng tôi"
      />
      <NameMeaning />
      <Tenets />
    </Stack>
  );
}

export default AboutUs;
