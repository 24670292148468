import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { navMenuItem } from "types";

import "./FullNavBar.css";

const fullNavHeight = 100;

function CustomMenuItem({ children, ...rest }) {
  return (
    <MenuItem {...rest} sx={{ fontFamily: "Linotte" }}>
      {children}
    </MenuItem>
  );
}

function FullNavBar({ courses, whyUs, company, news, library }) {
  const [shouldOpenCoursesMenu, setShouldOpenCoursesMenu] = useState(false);
  const [shouldOpenWhyUsMenu, setShouldOpenWhyUsMenu] = useState(false);
  const [shouldOpenCompanyMenu, setShouldOpenCompanyMenu] = useState(false);
  const [shouldOpenNewsMenu, setShouldOpenNewsMenu] = useState(false);
  const [shouldOpenLibraryMenu, setShouldOpenLibraryMenu] = useState(false);

  const navigate = useNavigate();

  const coursesMenuButtonRef = useRef();
  const whyUsMenuButtonRef = useRef();
  const companyMenuButtonRef = useRef();
  const newsMenuButtonRef = useRef();
  const libraryMenuButtonRef = useRef();

  const openCoursesMenu = () => setShouldOpenCoursesMenu(true);
  const closeCoursesMenu = () => setShouldOpenCoursesMenu(false);
  const openWhyUsMenu = () => setShouldOpenWhyUsMenu(true);
  const closeWhyUsMenu = () => setShouldOpenWhyUsMenu(false);
  const openCompanyMenu = () => setShouldOpenCompanyMenu(true);
  const closeCompanyMenu = () => setShouldOpenCompanyMenu(false);
  const openNewsMenu = () => setShouldOpenNewsMenu(true);
  const closeNewsMenu = () => setShouldOpenNewsMenu(false);
  const openLibraryMenu = () => setShouldOpenLibraryMenu(true);
  const closeLibraryMenu = () => setShouldOpenLibraryMenu(false);

  const handleClickCoursesMenuItem = (path) => {
    closeCoursesMenu();
    navigate(path);
  };
  const handleClickWhyUsMenuItem = (path) => {
    closeWhyUsMenu();
    navigate(path);
  };
  const handleClickCompanyMenuItem = (path) => {
    closeCompanyMenu();
    navigate(path);
  };
  const handleClickNewsMenuItem = (path) => {
    closeNewsMenu();
    navigate(path);
  };
  const handleClickLibraryMenuItem = (path) => {
    closeLibraryMenu();
    navigate(path);
  };

  return (
    <>
      <AppBar position="fixed" elevation={1}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <img
            src="/images/logo.png"
            className="logo"
            alt="logo"
            height={fullNavHeight}
            onClick={() => navigate("/")}
          />

          <Button ref={coursesMenuButtonRef} onClick={openCoursesMenu}>
            KHOÁ HỌC
          </Button>
          <Menu
            anchorEl={coursesMenuButtonRef.current}
            open={shouldOpenCoursesMenu}
            onClose={closeCoursesMenu}
          >
            {courses.map(({ title, path }) => (
              <CustomMenuItem
                key={title}
                onClick={() => handleClickCoursesMenuItem(path)}
              >
                {title}
              </CustomMenuItem>
            ))}
          </Menu>

          <Button ref={whyUsMenuButtonRef} onClick={openWhyUsMenu}>
            ĐIỂM ĐẶC BIỆT
          </Button>
          <Menu
            anchorEl={whyUsMenuButtonRef.current}
            open={shouldOpenWhyUsMenu}
            onClose={closeWhyUsMenu}
          >
            {whyUs.map(({ title, path }) => (
              <CustomMenuItem
                key={title}
                onClick={() => handleClickWhyUsMenuItem(path)}
              >
                {title}
              </CustomMenuItem>
            ))}
          </Menu>

          <Button ref={companyMenuButtonRef} onClick={openCompanyMenu}>
            GIỚI THIỆU
          </Button>
          <Menu
            anchorEl={companyMenuButtonRef.current}
            open={shouldOpenCompanyMenu}
            onClose={closeCompanyMenu}
          >
            {company.map(({ title, path }) => (
              <CustomMenuItem
                key={title}
                onClick={() => handleClickCompanyMenuItem(path)}
              >
                {title}
              </CustomMenuItem>
            ))}
          </Menu>

          <Button ref={newsMenuButtonRef} onClick={openNewsMenu}>
            TIN TỨC
          </Button>
          <Menu
            anchorEl={newsMenuButtonRef.current}
            open={shouldOpenNewsMenu}
            onClose={closeNewsMenu}
          >
            {news.map(({ title, path }) => (
              <CustomMenuItem
                key={title}
                onClick={() => handleClickNewsMenuItem(path)}
              >
                {title}
              </CustomMenuItem>
            ))}
          </Menu>

          <Button ref={libraryMenuButtonRef} onClick={openLibraryMenu}>
            THƯ VIỆN
          </Button>
          <Menu
            anchorEl={libraryMenuButtonRef.current}
            open={shouldOpenLibraryMenu}
            onClose={closeLibraryMenu}
          >
            {library.map(({ title, path }) => (
              <CustomMenuItem
                key={title}
                onClick={() => handleClickLibraryMenuItem(path)}
              >
                {title}
              </CustomMenuItem>
            ))}
          </Menu>

          <Button variant="contained" onClick={() => navigate("/lien-he")}>
            LIÊN HỆ
          </Button>
        </Stack>
      </AppBar>

      <Box sx={{ height: fullNavHeight }} />
    </>
  );
}

FullNavBar.propTypes = {
  courses: PropTypes.arrayOf(navMenuItem).isRequired,
  whyUs: PropTypes.arrayOf(navMenuItem).isRequired,
  company: PropTypes.arrayOf(navMenuItem).isRequired,
  news: PropTypes.arrayOf(navMenuItem).isRequired,
  library: PropTypes.arrayOf(navMenuItem).isRequired,
};

export default FullNavBar;
