import React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

function TenetContent({ image, borderColor, tenet, children }) {
  const upLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  return (
    <Stack
      direction="row"
      justifyContent="center"
      useFlexGap
      flexWrap="wrap"
      spacing={4}
      padding={2}
    >
      <Card
        elevation={0}
        sx={{
          position: "relative",
          borderColor,
          borderStyle: "dashed",
          borderRadius: 4,
          padding: 6,
          paddingRight: 8,
          marginRight: upLg ? -8 : 0,
          marginBottom: upLg ? 0 : -8,
        }}
      >
        <CardContent>
          <Stack maxWidth={650} spacing={2}>
            <Typography variant="h5" color="info.main" align="center">
              {tenet}
            </Typography>
            {children}
          </Stack>
        </CardContent>
      </Card>

      <img
        src={image}
        alt={tenet}
        loading="lazy"
        width={350}
        height={350}
        style={{ borderRadius: 30, zIndex: 1 }}
      />
    </Stack>
  );
}

export default TenetContent;
