import React from "react";
import PropTypes from "prop-types";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

function Benefit({ index, img, header, text }) {
  const upSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const even = index % 2 === 0;

  return (
    <Stack spacing={4}>
      <Stack
        direction="row"
        justifyContent="center"
        useFlexGap
        flexWrap="wrap"
        spacing={4}
        padding={2}
      >
        {even && upSm && img}

        <Stack alignItems="center" width={500} spacing={4}>
          <Typography
            variant="h3"
            color={`${even ? "primary" : "secondary"}.main`}
            align="center"
          >
            {header}
          </Typography>

          <Typography variant="body1" color="info.main">
            {text}
          </Typography>
        </Stack>

        {(!even || !upSm) && img}
      </Stack>
    </Stack>
  );
}

Benefit.propTypes = {
  index: PropTypes.number.isRequired,
  img: PropTypes.element.isRequired,
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Benefit;
