import React from "react";

import CourseTable from "components/pages/CoursePage/CourseTable";

const headers = [
  [
    {
      rowSpan: 1,
      content: "STT",
    },
    {
      rowSpan: 1,
      content: "Khoá",
    },
    {
      rowSpan: 1,
      content: "Tên CT",
    },
    {
      rowSpan: 1,
      content: "Số giờ",
    },
    {
      rowSpan: 1,
      content: "Số buổi",
    },
    {
      rowSpan: 1,
      content: "Số tuần",
    },
    {
      rowSpan: 1,
      content: "Số tháng",
    },
    {
      rowSpan: 1,
      content: "Tuổi",
    },
    {
      rowSpan: 1,
      content: "Giáo trình",
    },
    {
      rowSpan: 1,
      content: "Ghi chú",
    },
  ],
];

const rows = [
  [
    1,
    {
      rowSpan: 9,
      content: "SEED",
    },
    "S1A",
    45,
    30,
    15,
    3.75,
    {
      rowSpan: 9,
      content: "4-7",
    },
    "DOODLE TOWN 1",
    {
      rowSpan: 9,
      content:
        "Với học sinh mới hoàn toàn, bắt đầu từ S1A. Khi kết thúc khoá Doodle Town nếu học sinh đã học xong học kỳ 1 của lớp 1 hoặc hơn sẽ chuyển trực tiếp sang Power Up Startsmart, nếu học sinh chưa đủ tuổi sẽ học thêm chương trình dự bị tiểu học (PL)",
    },
  ],
  [2, "S1B", 45, 30, 15, 3.75, "DOODLE TOWN 1"],
  [3, "S1C", 45, 30, 15, 3.75, "DOODLE TOWN 1"],
  [4, "S2A", 45, 30, 15, 3.75, "DOODLE TOWN 2"],
  [5, "S2B", 45, 30, 15, 3.75, "DOODLE TOWN 2"],
  [6, "S2C", 45, 30, 15, 3.75, "DOODLE TOWN 2"],
  [7, "S3A", 45, 30, 15, 3.75, "DOODLE TOWN 3"],
  [8, "S3B", 45, 30, 15, 3.75, "DOODLE TOWN 3"],
  [9, "S3C", 45, 30, 15, 3.75, "DOODLE TOWN 3"],
  [
    10,
    {
      rowSpan: 9,
      content: "PRE-LEAF",
    },
    "PLA",
    45,
    30,
    15,
    3.75,
    {
      rowSpan: 2,
      content: "4-7",
    },
    "DOODLE TOWN 2 + OXPHORD PHONICS WORLD 1",
    {
      rowSpan: 2,
      content:
        "Học sinh 6 tuổi muốn học chương trình tiểu học nhưng chưa đủ tuổi để học Power Up",
    },
  ],
  [11, "PLB", 45, 30, 15, 3.75, "DOODLE TOWN 2 + OXPHORD PHONICS WORLD 1"],
];

function KindyTable() {
  return (
    <CourseTable
      headerBackgroundColor="primary.light"
      headers={headers}
      rows={rows}
    />
  );
}

export default KindyTable;
