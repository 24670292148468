import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import PageBanner from "components/common/PageBanner";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const pagesUnderConstruction = {
  "/tuyen-dung": {
    heading: "Tuyển dụng",
    pageName: "Tuyển dụng",
  },
  "/tai-lieu": {
    heading: "Tài liệu",
    pageName: "Tài liệu",
  },
};

function UnderConstruction() {
  const location = useLocation();
  const navigate = useNavigate();

  const { heading, pageName } = pagesUnderConstruction[location.pathname];

  return (
    <Stack spacing={10}>
      <PageBanner
        backgroundImage="/images/orange-banner.png"
        heading={heading}
        pageName={pageName}
      />

      <Stack alignItems="center" spacing={8} padding={4}>
        <Stack
          direction="row"
          justifyContent="center"
          useFlexGap
          flexWrap="wrap"
          spacing={2}
        >
          <img
            src="/images/construction-1.png"
            alt="construction-1"
            height={100}
          />
          <img
            src="/images/construction-2.png"
            alt="construction-2"
            height={100}
          />
          <img
            src="/images/construction-3.png"
            alt="construction-3"
            height={100}
          />
          <img
            src="/images/construction-4.png"
            alt="construction-4"
            height={100}
          />
        </Stack>

        <Stack alignItems="center">
          <Typography variant="h4" color="info.main" align="center">
            Trang web đang trong quá trình xây dựng.
          </Typography>
          <Typography variant="h4" color="info.main" align="center">
            Xin vui lòng quay lại sau.
          </Typography>
        </Stack>

        <Button variant="contained" size="large" onClick={() => navigate("/")}>
          Về trang chủ
        </Button>
      </Stack>
    </Stack>
  );
}

export default UnderConstruction;
