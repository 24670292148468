import React from "react";
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import "./Courses.css";

const courses = [
  {
    img: (
      <img
        className="bouncy-image"
        src="/images/kindy.png"
        alt="kindy"
        width={90}
      />
    ),
    backgroundColor: "primary.light",
    courseName: "MẦM NON KINDY",
    courseAge: "Học viên từ 3,5 đến 6 tuổi",
    path: "/khoa-hoc/kindy",
  },
  {
    img: (
      <img
        className="bouncy-image"
        src="/images/primary-basic.png"
        alt="basic"
        width={90}
      />
    ),
    backgroundColor: "secondary.light",
    courseName: "TIẾNG ANH PHỔ THÔNG",
    courseAge: "Học viên từ 6-15 tuổi",
    path: "/khoa-hoc/pho-thong",
  },
  {
    img: (
      <img
        className="bouncy-image"
        src="/images/primary-advanced.png"
        alt="advanced"
        width={90}
      />
    ),
    backgroundColor: "tertiary.blue.light",
    courseName: "TIẾNG ANH QUỐC TẾ",
    courseAge: "Học viên từ 6-15 tuổi",
    path: "/khoa-hoc/quoc-te",
  },
  {
    img: (
      <img
        className="bouncy-image"
        src="/images/exam-training.png"
        alt="training"
        width={90}
      />
    ),
    backgroundColor: "tertiary.yellow.light",
    courseName: "TIẾNG ANH LUYỆN THI",
    courseAge: "Học viên từ 6-18 tuổi",
    path: "/khoa-hoc/luyen-thi",
  },
];

function Courses() {
  const navigate = useNavigate();

  return (
    <Stack alignItems="center" spacing={2}>
      <Typography
        className="highlight"
        variant="h2"
        color="info.main"
        align="center"
        gutterBottom
      >
        Các khoá học
      </Typography>

      <Stack
        direction="row"
        justifyContent="center"
        useFlexGap
        flexWrap="wrap"
        spacing={2}
      >
        {courses.map(
          ({ img, backgroundColor, courseName, courseAge, path }) => (
            <Card
              key={courseName}
              elevation={5}
              sx={{ backgroundColor, borderRadius: "40px" }}
              onClick={() => navigate(path)}
            >
              <CardActionArea sx={{ width: 280, height: 280 }}>
                <CardMedia
                  sx={{
                    height: 170,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  {img}
                </CardMedia>
                <CardContent>
                  <Typography
                    variant="h6"
                    color="common.white"
                    align="center"
                    gutterBottom
                  >
                    {courseName}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="common.white"
                    align="center"
                  >
                    {courseAge}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          )
        )}
      </Stack>
    </Stack>
  );
}

export default Courses;
