import React from "react";
import { Helmet } from "react-helmet-async";

import PageBanner from "components/common/PageBanner";
import Secrets from "components/pages/WhyUs/Secrets";
import Stack from "@mui/material/Stack";

function WhyUs() {
  return (
    <Stack spacing={10}>
      <Helmet>
        <title>Điểm đặc biệt</title>
        <meta
          name="description"
          content="Lí do trung tâm Anh ngữ quốc tế The English Tree là lựa chọn tối ưu của phụ huynh, để con có 1 nền tảng giáo dục vững chắc."
        />
        <link rel="canonical" href="https://englishtree.edu.vn/diem-dac-biet" />
      </Helmet>

      <PageBanner
        backgroundImage="/images/green-banner.png"
        heading="Điểm đặc biệt"
        pageName="Điểm đặc biệt"
      />
      <Secrets />
    </Stack>
  );
}

export default WhyUs;
