import React from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

function Banner() {
  const navigate = useNavigate();

  const upSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const upMd = useMediaQuery((theme) => theme.breakpoints.up("md"));

  let padding = 4;
  if (upSm) {
    padding = 6;
  }
  if (upMd) {
    padding = 10;
  }

  let spacing = 4;
  if (upSm) {
    spacing = 6;
  }
  if (upMd) {
    spacing = 8;
  }

  return (
    <Stack direction="row" sx={{ backgroundColor: "primary.main" }}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        useFlexGap
        flexWrap="wrap"
        width="100%"
        p={padding}
        spacing={spacing}
      >
        <Stack width={upMd ? "50%" : "90%"} maxWidth={800}>
          <img
            src="https://i.postimg.cc/76SDD4hn/promotion.png"
            alt="promotion"
            width="100%"
          />
        </Stack>

        <Stack alignItems="center" spacing={4}>
          <Typography
            variant="h2"
            color="common.white"
            align="center"
            sx={{ userSelect: "none" }}
          >
            TRAO ƯU ĐÃI "VÀNG" HỌC TIẾNG ANH KHÔNG LO CHI PHÍ
          </Typography>
          <Typography
            variant="h5"
            color="common.white"
            align="center"
            sx={{ userSelect: "none" }}
          >
            The English Tree trao cơ hội "vàng" cho trẻ khởi đầu hành trình Anh
            Ngữ với gói ưu đãi học phí chỉ từ 800k
          </Typography>

          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={() => navigate("/lien-he#dang-ki-khoa-hoc")}
          >
            ĐĂNG KÍ NGAY
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Banner;
