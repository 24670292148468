import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import ContactDetails from "components/pages/ContactUs/ContactDetails";
import ContactForm from "components/common/ContactForm";
import PageBanner from "components/common/PageBanner";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

function ContactUs() {
  const { hash } = useLocation();

  const contactFormRef = useRef(null);

  const upMd = useMediaQuery((theme) => theme.breakpoints.up("md"));

  useEffect(() => {
    if (hash === "#dang-ki-khoa-hoc") {
      const navHeight = upMd ? 100 : 60;

      window.scrollTo({
        top: contactFormRef.current.offsetTop - navHeight,
        behavior: "smooth",
      });
    }
  }, [hash, upMd]);

  return (
    <Stack spacing={10}>
      <Helmet>
        <title>Liên hệ với The English Tree</title>
        <meta
          name="description"
          content="Liên lạc theo số 0966 766 839 - 0274 7306839 hoặc theenglishtree.edu@gmail.com."
        />
        <link rel="canonical" href="https://englishtree.edu.vn/lien-he" />
      </Helmet>

      <PageBanner
        backgroundImage="/images/blue-banner.png"
        heading="Liên hệ"
        pageName="Liên hệ"
      />

      <Stack alignItems="center">
        <div style={{ width: "100%", maxWidth: 800 }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3917.463813106936!2d106.75878859999999!3d10.928294200000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174d9bab2a4cfc9%3A0x11d9a76db2e4aa7f!2zNDAwIEzDqiBI4buTbmcgUGhvbmcsIFTDom4gQsOsbmgsIETEqSBBbiwgQsOsbmggRMawxqFuZywgVmlldG5hbQ!5e0!3m2!1sen!2sca!4v1683265058120!5m2!1sen!2sca"
            title="address-map"
            width="100%"
            height={400}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            style={{ border: 0, borderRadius: 10 }}
          />
        </div>
        <div style={{ width: "100%", maxWidth: 800 }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d979.4459992160913!2d106.75641074294458!3d10.904013993334756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174d8453d84b327%3A0xd7b745372c7d92b7!2zQ8O0bmcgVHkgVG5oaCBDw6J5IEFuaCBOZ-G7rw!5e0!3m2!1sen!2sca!4v1708754154521!5m2!1sen!2sca"
            title="address-map"
            width="100%"
            height={400}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            style={{ border: 0, borderRadius: 10 }}
          />
        </div>
      </Stack>

      <ContactDetails />

      <Stack
        ref={contactFormRef}
        alignItems="center"
        spacing={2}
        padding={1}
        sx={{ overflowX: "hidden" }}
      >
        <Typography
          className="highlight"
          variant="h2"
          color="info.main"
          align="center"
          gutterBottom
        >
          Đăng kí khoá học
        </Typography>
        <ContactForm />
      </Stack>
    </Stack>
  );
}

export default ContactUs;
