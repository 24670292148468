import React from "react";

import CourseTable from "components/pages/CoursePage/CourseTable";

const headers = [
  [
    {
      rowSpan: 1,
      content: "STT",
    },
    {
      rowSpan: 1,
      content: "Khoá",
    },
    {
      rowSpan: 1,
      content: "Tên CT",
    },
    {
      rowSpan: 1,
      content: "Số giờ",
    },
    {
      rowSpan: 1,
      content: "Số buổi",
    },
    {
      rowSpan: 1,
      content: "Số tuần",
    },
    {
      rowSpan: 1,
      content: "Số tháng",
    },
    {
      rowSpan: 1,
      content: "Tuổi",
    },
    {
      rowSpan: 1,
      content: "Giáo trình",
    },
    {
      rowSpan: 1,
      content: "Ghi chú",
    },
  ],
];

const rows = [
  [
    1,
    {
      rowSpan: 9,
      content: "Cambridge",
    },
    "STA1",
    24,
    12,
    6,
    1.5,
    {
      rowSpan: 9,
      content: "6-12",
    },
    "FUN FOR STARTERS",
    {
      rowSpan: 9,
      content: "Bằng cấp tiểu học",
    },
  ],
  [2, "STA2", 24, 12, 6, 1.5, "FUN FOR STARTERS"],
  [3, "STA3", 24, 12, 6, 1.5, "STARTERS PRACTICE TESTS"],
  [4, "MOV1", 30, 14, 7, 2, "FUN FOR MOVERS"],
  [5, "MOV2", 30, 14, 7, 2, "FUN FOR MOVERS"],
  [6, "MOV3", 24, 12, 6, 1.5, "MOVERS PRACTICE TESTS"],
  [7, "FLY1", 30, 15, 7.5, 2, "FUN FOR FLYERS"],
  [8, "FLY2", 30, 15, 7.5, 2, "FUN FOR FLYERS"],
  [9, "FLY3", 24, 12, 6, 1.5, "FLYERS PRACTICE TESTS"],
  [
    10,
    {
      rowSpan: 8,
      content: (
        <>
          <div>KET</div>
          <div>PET</div>
          <div>IELTS</div>
        </>
      ),
    },
    "KET 1",
    52,
    26,
    "",
    "",
    {
      rowSpan: 8,
      content: "Từ 12 tuổi",
    },
    "COMPLETE KEY FOR SCHOOL",
    {
      rowSpan: 3,
      content: "Bằng cấp THCS trở lên",
    },
  ],
  [11, "KET 2", 52, 26, "", "", "COMPLETE KEY FOR SCHOOL"],
  [12, "KET 3", 52, 26, "", "", "KEY FOR SCHOOL TRAINER"],
  [
    13,
    "PET 1",
    52,
    26,
    "",
    "",
    "COMPLETE PRELIMINARY FOR SCHOOL",
    {
      rowSpan: 5,
      content:
        "Học sinh lớp 9 cần chứng chỉ tiếng Anh B1 để nộp tuyển sinh vào lớp 10. HS có 2 lựa chọn: thi PET hoặc IELTS 4.5 (cả 2 chứng chỉ đều tương đương trình độ B1 khung tham chiếu chung châu Âu)",
    },
  ],
  [14, "PET 2", 52, 26, "", "", "COMPLETE PRELIMINARY FOR SCHOOL"],
  [15, "PET 3", 52, 26, "", "", "COMPLETE PRELIMINARY FOR SCHOOL"],
  [16, "PET 4", 52, 26, "", "", "COMPLETE PRELIMINARY FOR SCHOOL"],
  [17, "PET 5", 52, 26, "", "", "PET FOR SCHOOL TRAINERS"],
];

function ExamTrainingTable() {
  return (
    <CourseTable
      headerBackgroundColor="tertiary.yellow.light"
      headers={headers}
      rows={rows}
    />
  );
}

export default ExamTrainingTable;
