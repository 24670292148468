import React from "react";

import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Carousel from "components/core/Carousel";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

const testimonials = [
  {
    image: "/images/parent-1.png",
    backgroundColor: "primary.light",
    title: "Chị Thảo",
    subheader: "Phụ huynh bé 8 tuổi",
    content:
      "Chương trình đào tạo của trung tâm rất chất lượng, đặc biệt là các hoạt động ứng dụng tiếng Anh vào thực tiễn. Tôi rất ngạc nhiên khi con phỏng vấn cha mẹ ở nhà hay tự làm bản đồ. Cảm ơn trung tâm. Chúc trung tâm ngày càng phát triển.",
  },
  {
    image: "/images/parent-2.png",
    backgroundColor: "secondary.light",
    title: "Chị Hương",
    subheader: "Phụ huynh bé 6 tuổi",
    content:
      "Tôi rất hài lòng về chất lượng giảng dạy và dịch vụ tại trung tâm. Các thầy cô dạy rất có tâm và nhiệt tình. Tôi nhận được báo cáo về tình hình học tập của con hàng ngày nên luôn theo sát được tình hình học tập của con.",
  },
  {
    image: "/images/parent-3.png",
    backgroundColor: "tertiary.blue.light",
    title: "Anh Minh",
    subheader: "Phụ huynh bé 4 tuổi",
    content:
      "Đồng hành cùng trung tâm từ những khoá học đầu tiên, tôi cảm thấy may mắn vì mình đã lựa chọn đúng môi trường học cho con. Con rất thích đi học và đã tự tin bắt chuyện và giao tiếp bằng tiếng Anh với giáo viên nước ngoài.",
  },
];

function TestimonialCard({
  image,
  backgroundColor,
  title,
  subheader,
  content,
}) {
  return (
    <Card
      elevation={5}
      sx={{
        backgroundColor,
        borderRadius: 4,
        padding: 1,
        width: 240,
        height: 420,
      }}
    >
      <CardContent>
        <CardHeader
          avatar={
            <Avatar src={image} alt={title} sx={{ width: 75, height: 75 }} />
          }
          title={title}
          subheader={subheader}
          titleTypographyProps={{
            variant: "h7",
            color: "common.white",
          }}
          subheaderTypographyProps={{
            variant: "body2",
            color: "common.white",
          }}
        />

        <Typography variant="body1" color="common.white" padding={1}>
          {content}
        </Typography>
      </CardContent>
    </Card>
  );
}

function Testimonials() {
  const upLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  return (
    <Stack alignItems="center" justifyContent="center" padding={6} spacing={2}>
      <Typography
        className="highlight"
        variant="h2"
        color="info.main"
        align="center"
        gutterBottom
      >
        Chia sẻ của phụ huynh
      </Typography>

      {upLg && (
        <Stack direction="row" justifyContent="center" spacing={2}>
          {testimonials.map((testimonial) => (
            <TestimonialCard key={testimonial.title} {...testimonial} />
          ))}
        </Stack>
      )}
      {!upLg && (
        <Carousel spacing="large" startIndex={0} numberToShow={1}>
          {testimonials.map((testimonial) => (
            <TestimonialCard key={testimonial.title} {...testimonial} />
          ))}
        </Carousel>
      )}
    </Stack>
  );
}

export default Testimonials;
