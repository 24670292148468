import React from "react";
import { useNavigate } from "react-router-dom";

import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function CustomLink({ children, ...rest }) {
  return (
    <Link
      {...rest}
      component="button"
      underline="none"
      color="common.white"
      fontFamily="Montserrat"
      fontSize="0.9rem"
    >
      {children}
    </Link>
  );
}

function LogoBlock() {
  const navigate = useNavigate();

  const iconStyle = {
    cursor: "pointer",
  };

  return (
    <Stack alignItems="center" spacing={2}>
      <img
        src="/images/footer-logo.png"
        alt="footer-logo"
        height="75px"
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/")}
      />

      <Stack spacing={2} direction="row">
        <img
          src="/images/linkedin-logo.png"
          alt="linkedin-logo"
          height="20px"
          style={iconStyle}
          onClick={() => {}}
        />
        <img
          src="/images/facebook-logo.png"
          alt="facebook-logo"
          height="20px"
          style={iconStyle}
          onClick={() =>
            window.open(
              "https://www.facebook.com/theenglishtreeTET",
              "_blank",
              "noopener,noreferrer"
            )
          }
        />
        <img
          src="/images/instagram-logo.png"
          alt="instagram-logo"
          height="20px"
          style={iconStyle}
          onClick={() => {}}
        />
        <img
          src="/images/youtube-logo.png"
          alt="youtube-logo"
          height="20px"
          style={iconStyle}
          onClick={() => {}}
        />
      </Stack>
    </Stack>
  );
}

function CoursesBlock() {
  const navigate = useNavigate();

  return (
    <Stack alignItems="flex-start" spacing={1}>
      <Typography variant="h6" color="common.white" align="center">
        Khoá học
      </Typography>
      <CustomLink onClick={() => navigate("/khoa-hoc/kindy")}>
        Mầm non kindy
      </CustomLink>
      <CustomLink onClick={() => navigate("/khoa-hoc/pho-thong")}>
        Tiếng Anh phổ thông
      </CustomLink>
      <CustomLink onClick={() => navigate("/khoa-hoc/quoc-te")}>
        Tiếng Anh quốc tế
      </CustomLink>
      <CustomLink onClick={() => navigate("/khoa-hoc/luyen-thi")}>
        Tiếng Anh luyện thi
      </CustomLink>
    </Stack>
  );
}

function WhyUsBlock() {
  const navigate = useNavigate();

  return (
    <Stack alignItems="flex-start" spacing={1}>
      <Typography variant="h6" color="common.white" align="center">
        Điểm đặc biệt
      </Typography>
      <CustomLink
        onClick={() => navigate("diem-dac-biet?ddb=cong-thuc-bi-mat")}
      >
        40% công thức bí mật
      </CustomLink>
      <CustomLink
        onClick={() => navigate("diem-dac-biet?ddb=nguyen-lieu-chat-luong")}
      >
        20% nguyên liệu chất lượng
      </CustomLink>
      <CustomLink
        onClick={() => navigate("diem-dac-biet?ddb=chuyen-gia-tay-nghe-cao")}
      >
        20% chuyên gia tay nghề cao
      </CustomLink>
      <CustomLink
        onClick={() => navigate("diem-dac-biet?ddb=trang-tri-hai-hoa")}
      >
        10% trang trí hài hoà
      </CustomLink>
      <CustomLink onClick={() => navigate("diem-dac-biet?ddb=phuc-vu-chu-dao")}>
        10% phục vụ chu đáo
      </CustomLink>
    </Stack>
  );
}

function CompanyBlock() {
  const navigate = useNavigate();

  return (
    <Stack alignItems="flex-start" spacing={1}>
      <Typography variant="h6" color="common.white" align="center">
        Giới thiệu
      </Typography>
      <CustomLink onClick={() => navigate("ve-chung-toi")}>
        Về chúng tôi
      </CustomLink>
      <CustomLink onClick={() => navigate("tuyen-dung")}>Tuyển dụng</CustomLink>
    </Stack>
  );
}

function NewsBlock() {
  const navigate = useNavigate();

  return (
    <Stack alignItems="flex-start" spacing={1}>
      <Typography variant="h6" color="common.white" align="center">
        Tin tức
      </Typography>
      <CustomLink onClick={() => navigate("tin-tuc/uu-dai-vang")}>
        Ưu đãi vàng
      </CustomLink>
    </Stack>
  );
}

function LibraryBlock() {
  const navigate = useNavigate();

  return (
    <Stack alignItems="flex-start" spacing={1}>
      <Typography variant="h6" color="common.white" align="center">
        Thư viện
      </Typography>
      <CustomLink onClick={() => navigate("blog")}>Blog</CustomLink>
      <CustomLink onClick={() => navigate("tai-lieu")}>Tài liệu</CustomLink>
    </Stack>
  );
}

function Footer() {
  return (
    <Stack
      alignItems="center"
      justifyContent="flex-end"
      spacing={6}
      padding={8}
      sx={{ backgroundColor: "info.main" }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        useFlexGap
        flexWrap="wrap"
        spacing={8}
      >
        <LogoBlock />
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          useFlexGap
          flexWrap="wrap"
          spacing={8}
        >
          <CoursesBlock />
          <WhyUsBlock />
          <CompanyBlock />
          <NewsBlock />
          <LibraryBlock />
        </Stack>
      </Stack>

      <Typography variant="body2" color="common.white" align="center">
        Copyright © 2023 The English Tree. All Rights Reserved.
      </Typography>
    </Stack>
  );
}

export default Footer;
