import React from "react";

import ContactForm from "components/common/ContactForm";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function Trial() {
  return (
    <Stack
      alignItems="center"
      padding={1}
      spacing={2}
      sx={{ overflowX: "hidden" }}
    >
      <Typography
        className="highlight"
        variant="h2"
        color="info.main"
        align="center"
        gutterBottom
      >
        Trải nghiệm khoá học
      </Typography>

      <ContactForm />
    </Stack>
  );
}

export default Trial;
