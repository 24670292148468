import React from "react";

import CourseTable from "components/pages/CoursePage/CourseTable";

const headers = [
  [
    {
      rowSpan: 1,
      content: "STT",
    },
    {
      rowSpan: 1,
      content: "Khoá",
    },
    {
      rowSpan: 1,
      content: "Tên CT",
    },
    {
      rowSpan: 1,
      content: "Số giờ",
    },
    {
      rowSpan: 1,
      content: "Số buổi",
    },
    {
      rowSpan: 1,
      content: "Số tuần",
    },
    {
      rowSpan: 1,
      content: "Số tháng",
    },
    {
      rowSpan: 1,
      content: "Tuổi",
    },
    {
      rowSpan: 1,
      content: "Giáo trình",
    },
    {
      rowSpan: 1,
      content: "Ghi chú",
    },
  ],
];

const rows = [
  [
    1,
    {
      rowSpan: 15,
      content: "LEAF",
    },
    "LSA",
    52.5,
    30,
    15,
    3.75,
    {
      rowSpan: 15,
      content: "7-12",
    },
    "POWER UP START SMART",
    {
      rowSpan: 15,
      content: (
        <>
          <div style={{ marginBottom: "1rem" }}>
            Áp dụng cho HS đã học ít nhất 1 kỳ của lớp 1 (biết chữ)
          </div>
          <div>
            Bổ sung bài dạy chữ cái và phát âm 1-2 buổi đầu cho học sinh vào
            level mới ngoài chương trình học (bằng phụ đạo)
          </div>
        </>
      ),
    },
  ],
  [2, "LSB", 52.5, 30, 15, 3.75, "POWER UP START SMART"],
  [3, "LSC", 52.5, 30, 15, 3.75, "POWER UP START SMART"],
  [4, "L1A", 52.5, 30, 15, 3.75, "POWER UP 1"],
  [5, "L1B", 52.5, 30, 15, 3.75, "POWER UP 1"],
  [6, "L1C", 52.5, 30, 15, 3.75, "POWER UP 1"],
  [7, "L2B", 52.5, 30, 15, 3.75, "POWER UP 2"],
  [8, "L2B", 52.5, 30, 15, 3.75, "POWER UP 2"],
  [9, "L2C", 52.5, 30, 15, 3.75, "POWER UP 2"],
  [10, "L3A", 52.5, 30, 15, 3.75, "POWER UP 3"],
  [11, "L3B", 52.5, 30, 15, 3.75, "POWER UP 3"],
  [12, "L3C", 52.5, 30, 15, 3.75, "POWER UP 3"],
  [13, "L4A", 52.5, 30, 15, 3.75, "POWER UP 4"],
  [14, "L4B", 52.5, 30, 15, 3.75, "POWER UP 4"],
  [15, "L4C", 52.5, 30, 15, 3.75, "POWER UP 4"],
  [
    16,
    {
      rowSpan: 9,
      content: "FRUIT",
    },
    "F1A",
    52.5,
    30,
    15,
    3.75,
    {
      rowSpan: 9,
      content: "11-16",
    },
    "THINK 1",
    {
      rowSpan: 9,
      content:
        "Dành cho học sinh đi từ chương trình Power Up lên. Kết thúc lộ trình, học sinh đạt được chứng chỉ Cambridge Key for Schools (tương đương trình độ A2 khung tham chiếu chung châu Âu). Sau đó, học sinh chọn học thi chứng chỉ PET hoặc IELTS",
    },
  ],
  [17, "F1B", 52.5, 30, 15, 3.75, "THINK 1"],
  [18, "F1C", 52.5, 30, 15, 3.75, "THINK 1"],
  [19, "F1D", 52.5, 30, 15, 3.75, "THINK 1"],
  [20, "F2A", 52.5, 30, 15, 3.75, "THINK 2"],
  [21, "F2B", 52.5, 30, 15, 3.75, "THINK 2"],
  [22, "F2C", 52.5, 30, 15, 3.75, "THINK 2"],
  [23, "F2D", 52.5, 30, 15, 3.75, "THINK 2"],
  [24, "KET 3", 52.5, 30, 15, 3.75, "KEY FOR SCHOOL TRAINER"],
];

function PrimaryAdvancedTable() {
  return (
    <CourseTable
      headerBackgroundColor="tertiary.blue.light"
      headers={headers}
      rows={rows}
    />
  );
}

export default PrimaryAdvancedTable;
